import React,{useState,useEffect} from 'react'
import back from "../../../images/icons/back.svg"
import plus2 from "../../../images/icons/plus2.svg"
import delet from "../../../images/icons/delete.svg"
import plus from "../../../images/icons/plus.svg"
import search from "../../../images/icons/search.svg"
import success from "../../../images/success.svg"
import deleteRed from "../../../images/icons/deleteRed.svg"
import { ThreeDots } from 'react-loader-spinner'
import { Link } from 'react-router-dom'
import axios from 'axios'
import settings from '../../../components/getrequest'
import { LineWave } from  'react-loader-spinner'
import { format } from 'date-fns';

function GroupDetails() {
  const [error, setError] = useState(0)
  const [processing, setProcessing] = useState(0)
  const [base, setBase] = useState(sessionStorage.getItem('prefix') ? sessionStorage.getItem('prefix'): 'user')
  let urlbase = process.env.REACT_APP_BASE;
  const [groupDetails, setGroupDetails] = useState({})
  const [membersData, setmembersData] = useState([])

  const[fetching, setFetching] = useState(1)
  const sliceValue = 4
  const [membersArray, setmembersArray] = useState([membersData.slice(0,sliceValue)])
  const[membersCurrent, setmembersCurrent] = useState(membersData.slice(0,sliceValue))
  const [currentPage,setCurrentPage] = useState(0)

  useEffect(() => {
    //get groups 
    axios.get(urlbase+'/api/groups/single?id='+sessionStorage.getItem('group_id'),settings())
    .then((res) => {
        if(res.data.code == 200){
          setGroupDetails(res.data.data.details)
          setmembersData(res.data.data.members)
          setmembersArray([res.data.data.members.slice(0,sliceValue)])
          setmembersCurrent(res.data.data.members.slice(0,sliceValue))
        }else{
            setError(res.data.message)
            setProcessing(0)
        }
    }).catch((e) => {
        setError('There was an internal error, please try again later')
        setProcessing(0)
    })
  },[])


useEffect(()=>{
   createPages() 
  setFetching(0)
},[])
const createPages = async () => {
  let L = membersData.length
  let last_index = sliceValue
  if(membersData.length > sliceValue) {
      for(L; L-sliceValue > 0; L-sliceValue){
          L = L-sliceValue;
          let new_last_index = last_index+sliceValue
          let NewObject = membersData.slice(last_index,new_last_index)
          last_index = new_last_index
          if(NewObject.length > 0){
              membersArray.push(NewObject)
          } 
      }
      const updatedmembers = [...membersArray]
      setmembersArray(updatedmembers)
  }else{
      setmembersArray([membersData])
  } 
}
const PreviousPage = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  setCurrentPage(currentPage > 0 ? currentPage-1 : currentPage)
  if(currentPage > 0){
    setmembersCurrent(membersArray[currentPage-1])
    // setmembersCurrentDefault(membersArray[currentPage-1])
  }
}
const NextPage = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  setCurrentPage(currentPage == membersArray.length-1 ? currentPage : currentPage+1)
  if(currentPage == membersArray.length-1){

  }else{
    setmembersCurrent(membersArray[currentPage+1])
    // setmembersCurrentDefault(membersArray[currentPage+1])
  }
}
const numberedPage = (id) => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  setCurrentPage(id)
  setmembersCurrent(membersArray[id])
  // setmembersCurrentDefault(membersArray[id])
}
const [searchItem, setSearchItem] = useState("");
//Active members
const [activemembers, setActivemembers] = useState()
const changeActiveDon = (id) => {
  if(activemembers == id) setActivemembers(null)
  else setActivemembers(id)
}

//delete members
const [deleteModal, setDeleteModal] = useState()
const [memberDeleted, setMemberDeleted] = useState()
const setTheDelete = (id) => {
  // alert()
 // sessionStorage.setItem('group_id',id)
  setDeleteModal(id)
}

const [deleteProcessing,setDeleteProcessing] = useState()
const performDelete = () => {
  setDeleteProcessing(1)
  axios.delete(urlbase+'/api/groups/member?id='+deleteModal+'&group_id='+sessionStorage.getItem('group_id'),settings())
  .then((res) => {
    if(res.data.code == 200){
      setMemberDeleted(1)
      setDeleteModal(0)
      alert('Member removed successfully')
      window.location.reload()
    }else{
      alert(res.data.message);
    }
    setDeleteProcessing(0)
  }).catch(() => {
    alert('There was an error, please try again');
    setDeleteProcessing(0)
  })
}

//view details
const viewDetails = () => {
  window.location.href="/member-details"
}

//add donation
const [addDonation, setAddDonation] = useState()
const [donationAdded, setDonationAdded] = useState()
const createDonation = () => {
    setAddDonation(0)
    setDonationAdded(1)
}
//add member
const [addMember, setAddMember] = useState()
//create member
const [memberCreated, setMemberCreated] = useState()
const [addingMember, setAddingMember] = useState()
const [newMemberData, setNewMemberdata] = useState({group_id:sessionStorage.getItem('group_id')})
const createMember = () => {
  if(!newMemberData.member_id){
    alert('Input member Id')
    return
  }
  setAddingMember(1)
  setMemberCreated(1)
  axios.post(urlbase+'/api/groups/add',newMemberData,settings())
  .then((res) => {
    if(res.data.code == 200){
      alert('Member added successfully')
      window.location.reload()
      setAddingMember(0)
    }else{
      alert(res.data.message);
      setAddingMember(0)
    }
  }).catch(() => {
    alert('There was an error, please try again');
    setAddingMember(0)
  })
}
const [memberDonation, setMemberDonation] = useState({})
const [addingDonation, setAddingDonation] = useState()
const performAddition = () => {
  if(!memberDonation.payment_date || !memberDonation.payment_method){
    alert('Please fill all fields correctly')
    return
  }
  setAddingDonation(1)
    memberDonation.member_id = addDonation
    setAddMember(0)
    axios.post(urlbase+'/api/givings/create',memberDonation,settings())
    .then((res) => {
      if(res.data.code == 200){
        setAddingMember(0)
        setAddDonation(0)
        setDonationAdded(1)
        //alert('Donation added successfully')
        //window.location.reload()
      }else{
        alert(res.data.message);
        //setAddDonation(0)
      }
      setAddingDonation(0)
    }).catch(() => {
      alert('There was an error, please try again');
      setAddingDonation(0)
    })
}
const AddDonationData = (field, value) => {
  setMemberDonation({...memberDonation,[field] : value})
}
//delete donation
  return (
    <>
    {deleteModal ?
      <div id="defaultModal" tabIndex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
          <div className="relative w-[50%] lg:w-[35%] mt-[10%] max-h-full mx-auto text-[#333]">
              <div className="relative bg-white shadow pt-4 rounded-[8px] text-center">
                  <div className="flex items-start justify-between px-8 pb-4">
                      <h3 className="text-[#121927] text-[20px] font-[600]">
                      </h3>
                      <button onClick={() => setDeleteModal(0)} type="button" className="text-gray-300 font-[200] bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center" data-modal-hide="defaultModal">
                          <svg aria-hidden="true" className="w-5 h-5 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                          <span className="sr-only">Close modal</span>
                      </button>
                  </div>
                  <div className='flex flex-col space-y-4 mt-3 px-5'>
                    <div className='grid mb-2'><img className='mx-auto' src={deleteRed} /></div>
                    <span className='text-[#121927] font-[600] text-[24px]'>Remove Member from Group</span>
                    <span className='text-[#4F4F4F] text-[16px]'>You will have to re-add the member again. Are you sure you want to delete?</span>
                  </div>
                  <div className="flex flex-col mt-5 space-y-3 px-8 py-4 rounded-b-[8px]">
                      <button onClick={performDelete} data-modal-hide="defaultModal" type="button" className="border-[1px] w-full h-[50px] bg-[#EB5757] rounded-[8px] border-none text-white">Yes, remove member</button>
                      <button onClick={() => setDeleteModal(0)} data-modal-hide="defaultModal" type="button" className="border-[#828282] w-full h-[50px] rounded-[8px] bg-[#F7F7F7] text-[#333333]">No, Cancel</button>
                  </div>
              </div>
          </div>
      </div>
      :null}
    {donationAdded ?
        <div id="defaultModal" tabindex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
            <div className="relative w-[50%] lg:w-[48%] mt-[2%] max-h-full mx-auto text-[#333]">
                <div className="relative bg-white shadow pt-4 rounded-[8px] px-8 text-center">
                    <div className="flex items-start justify-between px-8">
                        <h3 className="text-[#121927] text-[20px] font-[600]">
                        </h3>
                    </div>
                    <div className='flex flex-col space-y-2 mt-3 px-5'>
                      <div className='grid mb-2'><img className='mx-auto w-[50%]' src={success} /></div>
                      <span className='text-[#121927] font-[600] text-[24px]'>Awesome! Donation has been successfully added</span>
                      <span className='text-[#4F4F4F] text-[16px]'>All members' donation can be found on the members page. check to see the specifics of each member's contribution.</span>
                    </div>
                    <div className="flex flex-row mt-5 space-x-3 px-8 pb-4 justify-center rounded-b-[8px]">
                        <Link to={'/'+base+'/'} data-modal-hide="defaultModal" type="button" className="border-[1px] w-full h-[50px] bg-white rounded-[8px] border-[1px] text-[#121927] grid my-auto"><p className="my-auto">Go to Dashboard</p></Link>
                        <Link to={'/'+base+'/giving'} data-modal-hide="defaultModal" type="button" className="border-[#828282] w-full h-[50px] rounded-[8px] bg-[#B30000] text-white grid"><p className="my-auto">View Donation Details</p></Link>
                    </div>
                </div>
            </div>
        </div>
        :null}
      {addMember ?
      <div id="defaultModal" tabIndex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
      <div className="relative w-[95%] lg:w-[40%] mt-[25%] lg:mt-[10%] max-h-full mx-auto text-[#333]">
          <div className="relative bg-white shadow pt-3 rounded-[8px]">
              <div className="flex items-start justify-between border-b-[1px] px-8 pb-3">
                  <h3 className="text-[#121927] text-[20px] font-[600]">
                      Add New Member
                  </h3>
                  <button onClick={() => setAddMember(0)} type="button" className="text-gray-300 font-[200] bg-transparent hover:bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center" data-modal-hide="defaultModal">
                      <svg aria-hidden="true" className="w-5 h-5 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                      <span className="sr-only">Close modal</span>
                  </button>
              </div>
              <div className='flex flex-col mt-3 mb-20 px-6 mt-7 text-start space-y-4'>
                <div className='flex flex-col space-y-2'>
                  <div className=''>Member ID</div>
                  <input onChange={(e) => {setNewMemberdata({...newMemberData,member_id:e.target.value})}} className='relative w-full h-[40px] pl-2 rounded-[8px] border-[1px] text-[#8A8A8A] outline-none' placeholder='Input member Id' />
                </div>
                {/* <div className='flex flex-col space-y-2'>
                  <div className=''>Donation (Optional)</div>
                  <input className='relative w-full h-[40px] pl-2 rounded-[8px] border-[1px] text-[#8A8A8A] outline-none' placeholder='Enter amount for this giving' />
                </div> */}
              </div>
              <div className="flex flex-row justify-end mt-5 space-x-2 px-8 bg-[#F7F7F7] py-4 rounded-b-[8px]">
              {addingMember ? 
                    <LineWave height="100" width="100" color="#B30000" ariaLabel="line-wave" wrapperStyle={{marginTop:'-40px'}}
                    wrapperClass="" visible={true} firstLineColor="" middleLineColor="" lastLineColor=""/> : <>
                  <button onClick={() => setAddMember(0)} data-modal-hide="defaultModal" type="button" className="border-[1px] border-[#B30000] w-[102px] h-[50px] rounded-[8px] bg-white text-[#B30000]">Cancel</button>
                  <button onClick={createMember} data-modal-hide="defaultModal" type="button" className={`border-[1px] w-[150px] h-[50px] bg-[#B30000] rounded-[8px] border-none text-white`}>Add Member</button>
                  </> }
              </div>
          </div>
      </div>
  </div>
      :null}

    {addDonation ?
      <div id="defaultModal" tabIndex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
          <div className="relative w-[95%] lg:w-[40%] mt-[15%] lg:mt-[2%] max-h-full mx-auto text-[#333]">
              <div className="relative bg-white shadow pt-3 rounded-[8px]">
                  <div className="flex items-start justify-between border-b-[1px] px-8 pb-3">
                      <h3 className="text-[#121927] text-[20px] font-[600]">
                          Add Donation
                      </h3>
                      <button onClick={() => setAddDonation(0)} type="button" className="text-gray-300 font-[200] bg-transparent hover:bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center" data-modal-hide="defaultModal">
                          <svg aria-hidden="true" className="w-5 h-5 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                          <span className="sr-only">Close modal</span>
                      </button>
                  </div>
                  <div className='flex flex-col mt-3 mb-20 px-6 mt-7 text-start space-y-4'>
                    <div className='flex flex-col space-y-2'>
                      <div className=''>Amount</div>
                      <input onChange={(e) => AddDonationData('amount',e.target.value)} type='number' className='relative w-full h-[40px] pl-2 rounded-[8px] border-[1px] text-[#8A8A8A] outline-none' placeholder='Enter amount for this giving' />
                    </div>
                    <div className='flex flex-col space-y-2'>
                      <div className=''>Type</div>
                      <select onChange={(e) => AddDonationData('type',e.target.value)} className='relative w-full h-[40px] pl-2 rounded-[8px] border-[1px] text-[#8A8A8A] outline-none' >
                        <option value={null}>Select Donation Type</option>
                        <option value={1}>Individual(Offering)</option>
                        <option value={2}>Individual(Tithe)</option>
                        <option value={null}>Others</option>
                      </select>
                    </div>
                    <div className='flex flex-col space-y-2'>
                      <div className=''>Payment method</div>
                      <select onChange={(e) => AddDonationData('payment_method',e.target.value)} className='relative w-full h-[40px] pl-2 rounded-[8px] border-[1px] text-[#8A8A8A] outline-none' >
                        <option value=''>Select payment method</option>
                        <option value='Bank Transfer'>Bank Transfer</option>
                        <option value='Cash'>Cash</option>
                        <option value='POS'>POS</option>
                      </select>
                    </div>
                    <div className='flex flex-col space-y-2'>
                      <div className=''>Payment Date</div>
                      <input onChange={(e) => AddDonationData('payment_date',e.target.value)} type='date' className='relative w-full h-[40px] pl-2 rounded-[8px] border-[1px] text-[#8A8A8A] outline-none'/>
                    </div>
                  </div>
                  <div className="flex flex-row justify-end mt-5 space-x-2 px-8 bg-[#F7F7F7] py-4 rounded-b-[8px]">
                  {addingDonation ? 
                    <LineWave height="100" width="100" color="#B30000" ariaLabel="line-wave" wrapperStyle={{marginTop:'-40px'}}
                    wrapperClass="" visible={true} firstLineColor="" middleLineColor="" lastLineColor=""/> : <>
                      <button onClick={() => setAddDonation(0)} data-modal-hide="defaultModal" type="button" className="border-[1px] border-[#B30000] w-[102px] h-[50px] rounded-[8px] bg-white text-[#B30000]">Cancel</button>
                      <button onClick={performAddition} data-modal-hide="defaultModal" type="button" className={`border-[1px] w-[150px] h-[50px] bg-[#B30000] rounded-[8px] border-none text-white`}>Add Donation</button>
                      </> }
                  </div>
              </div>
          </div>
      </div>
      :null}
       <div className='grid grid-cols-1 mt-5 text-start mb-10'>
        <div className='col-span-1 grid grid-cols-1 px-8 border-b pb-5'>
          <Link to={'/'+base+'/groups'} className='col-span-1 flex flex-row space-x-3'>
              <img src={back}/>
              <span className='text-[#4F4F4F] text-[16px]'>Back</span>
          </Link>
          <p className='text-[#121927] font-[600] text-[24px] mt-5'>Group Details</p>
          <span className='text-[#4F4F4F] mt-2 flex flex-col space-y-3 lg:space-y-0 lg:flex-row justify-between lg:items-center'>
            <span className='w-[65%]'>Comprehensive details of the group, list of members and all other details relating to the group.</span>
            {/* <span className='text-[#333333] font-[600] text-[40px] flex flex-col bg-[#F7F7F7] px-2 py-2'>
                <span>£{groupDetails.total_amount ? parseInt(groupDetails.total_amount).toLocaleString() : 0}</span>
                <span className='text-[16px] font-[400]'>Total donation</span>
            </span> */}
          </span>
        </div>
        <div className='col-span-1 grid grid-cols-6 gap-x-3 p-8 gap-y-5'>
          <div className='col-span-6 lg:col-span-2 flex flex-col space-y-2'>
            <label className='text-[#828282]'>Group Name</label>
            <div className='h-[50px] rounded-[8px] py-3 px-3 border-[1px] text-[#4F4F4F]'>{groupDetails.name}</div>
          </div>
          <div className='col-span-6 lg:col-span-2 flex flex-col space-y-2'>
            <label className='text-[#828282]'>Group ID</label>
            <div className='h-[50px] rounded-[8px] py-3 px-3 border-[1px] text-[#4F4F4F]'>{groupDetails.group_id}</div>
          </div>
          <div className='col-span-6 lg:col-span-2 flex flex-col space-y-2'>
            <label className='text-[#828282]'>Creation Date</label>
            <div className='h-[50px] rounded-[8px] py-3 px-3 border-[1px] text-[#4F4F4F]'>{groupDetails.date_created}</div>
          </div>
          <div className='col-span-6 lg:col-span-6 flex flex-col space-y-2'>
            <label className='text-[#828282]'>Group Description</label>
            <div className='h-[50px] rounded-[8px] py-3 px-3 border-[1px] text-[#4F4F4F] h-auto flex flex-col space-y-2'>
                <p>{groupDetails.description}</p>
            </div>
          </div>
          <div className='col-span-6 mt-3'><hr/></div>
        </div>
        <div className='col-span-1 flex flex-col text-start space-y-2 lg:space-y-0 lg:flex-row justify-between px-8 lg:items-center'>
            <span className=''>Members</span>
            <div className='flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-2 lg:items-center'>
                <span className='text-[#4F4F4F] hidden lg:block'>{membersData.length} {membersData.length > 1 ? 'members' : 'member'}</span>
                <div className='relative'>
                    <div className='absolute top-3 z-40 left-4'><img src={search} /></div>
                    <input onChange={(e) => setSearchItem(e.target.value)} className='relative h-[40px] w-full lg:w-[321px] bg-[#F7F7F7] h-[40px] pl-10 rounded-[8px] border-[1px] text-[#8A8A8A] outline-none' placeholder='Search' />
                </div>
                <button onClick={() => setAddMember(1)} className='flex flex-row justify-center bg-[#B30000] text-white p-2 rounded-[4px] h-[40px] items-center space-x-2 cursor-pointer'>
                    <span><img src={plus2} /></span>
                    <span>Add member</span>
                </button>
            </div>
        </div>
        <div className='col-span-1 border-[1px] rounded-[8px] mx-8 mt-5'>
          <table className="table-auto overflow-x-scroll block divide-y divide-gray-200 ">
            <thead className='bg-[#F7F7F7] h-[40px]'>
                <tr className='px-4 py-1'>
                <th className='px-4 py-3 text-start text-[#292D32] font-[400] w-[14.29%]'>S/N</th>
                <th className='px-4 py-1 text-start text-[#292D32] font-[400] w-[14.29%]'>Name</th>
                <th className='px-4 py-1 text-start text-[#292D32] font-[400] w-[14.29%]'>Member ID</th>
                <th className='px-4 py-1 text-start text-[#292D32] font-[400] w-[14.29%]'>Email Address</th>
                <th className='px-4 py-1 text-start text-[#292D32] font-[400] w-[14.29%]'>Donations Count</th>
                <th className='px-4 py-1 text-start text-[#292D32] font-[400] w-[14.29%]'>Date</th>
                <th className='px-6 py-1 text-start text-[#292D32] font-[400] text-[#F7F7F7] w-[14.29%]'>Action</th>
                </tr>
            </thead>
            <tbody className='divide-y divide-gray-200'>
                {membersCurrent.filter((row) =>
                !searchItem.length || row.name
                .toString()
                .toLowerCase()
                .includes(searchItem.toString().toLowerCase()) 
                ).map(({id,first_name,last_name,member_id,email,donations,created_at},index) => {
                    return (
                        <tr key={id}>
                            <td className='px-4 py-3 text-start text-[#4F4F4F]'>{index+1}</td>
                            <td className='px-4 py-3 text-start text-[#4F4F4F]'>{first_name+' '+last_name}</td>
                            <td className='px-4 py-3 text-start text-[#4F4F4F]'>{member_id}</td>
                            <td className='px-4 py-3 text-start text-[#4F4F4F]'>{email}</td>
                            <td className='px-4 py-3 text-start text-[#4F4F4F]'>{donations}</td>
                            <td className='px-4 py-3 text-start text-[#4F4F4F]'>{format(new Date(created_at),'yyyy-mm-dd')}</td>
                            <td className='px-6 py-6 text-start text-[#4F4F4F] flex flex-row space-x-3 items-center justify-center'>
                                <span onClick={() => setAddDonation(member_id)} className='cursor-pointer'><img className='w-[30px] h-[30px]' src={plus} /></span>
                                <span className='cursor-pointer' onClick={() => setTheDelete(id)}><img className='w-[20px] h-[20px]' src={delet} /></span>
                            </td>
                        </tr>
                        )
                    }) 
                }
            </tbody>
          </table>
        </div>
        <div className='col-span-1 flex flex-row justify-between items-center mx-8'>
          {searchItem? '' : <span className='text-[#333333] text-[16px] text-start'>Showing <span>{membersCurrent.length}</span>/<span>{membersData.length}</span> results</span>}
          {fetching ? 
            <div className='col-span-8 lg:col-span-6 flex flex-col justify-center items-center '>
                <ThreeDots height="80" width="80" radius="9" color="#333333" ariaLabel="three-dots-loading"
                wrapperStyle={{}} wrapperClassName="" visible={true}
                />
            </div> 
            :
          <div className={`ml-auto grid grid-cols-6 gap-x-4`}>
                <div className={`mt-5 col-span-6 flex flex-row gap-x-3 mb-5 lg:ml-auto`}>
                    <div onClick={PreviousPage} className={`border-[#BCBCBC] h-[40px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto border-[1px] px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center text-[#BCBCBC] hover:text-[#1D242D] cursor-pointer`}>{`<`}</div>
                    {membersArray.slice(0,5).map((membersArray,index)=> {
                        return (
                            <div key={index} onClick={()=>numberedPage(index)} className={`transitiion-all text-center h-[40px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto text-start duration-500 border-[#BCBCBC] font-[600] text-[18px] border-[1px] px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center ${currentPage == index ? 'text-white bg-[#B30000] border-[#B30000] hover:bg-[#B30000] hover:text-white' :'text-[#292D32] bg-white hover:text-white hover:bg-[#B30000]'} hover:text-[#1D242D] cursor-pointer`}>{index+1}</div>
                        )
                    })}
                    <div onClick={NextPage} className={`border-[#BCBCBC] border-[1px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center text-[#BCBCBC] hover:text-[#1D242D] cursor-pointer`}>{`>`}</div>
                </div>
            </div>
          }
        </div>
       </div>
    </>
  )
}

export default GroupDetails