import React from "react";
import Header from "../header";
import Doc from "./doc";
import Footer from "../footer";

function Support() {
  return (
    <>
      <div className="">
        <header>
          <Header></Header>
        </header>
        <body>
          <Doc/>
        </body>
        <footer>
          <Footer />
        </footer>
      </div>
    </>
  );
}

export default Support;
