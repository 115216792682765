import React, {useState,useEffect} from 'react'
import search from "../../../images/icons/search.svg"
import { ThreeDots } from 'react-loader-spinner'
import filter from "../../../images/icons/filter.svg"
import HistorySingle from './historySingle'
import axios from 'axios'
import { LineWave } from  'react-loader-spinner'
import settings from '../../../components/getrequest'
import { format } from 'date-fns';

function ClaimHistory() {
  const [base, setBase] = useState(sessionStorage.getItem('prefix') ? sessionStorage.getItem('prefix'): 'user')
  let urlbase = process.env.REACT_APP_BASE;
  const [error, setError] = useState(0)
  const [processing, setProcessing] = useState(0)
    const claimsheaders = [
        { label: "id", key: "id" },
        { label: "name", key: "name" },
        { label: "date", key: "date" },
        { label: "member_id", key: "member_id" },
        { label: "amount", key: "amount" },
        { label: "type", key: "type" },
        { label: "payment", key: "payment" },
      ]
      const [claimsData, setclaimsData] = useState([])
    const[fetching, setFetching] = useState(1)
    const sliceValue = 4
    const [givingArray, setgivingArray] = useState([])
    const[givingCurrent, setgivingCurrent] = useState([])
    const[givingCurrentDefault, setgivingCurrentDefault] = useState()
    const [currentPage,setCurrentPage] = useState(0)
    useEffect(()=>{
      //get running claims
      axios.get(urlbase+'/api/claims/history',settings())
      .then((res) => {
          if(res.data.code == 200){
            setclaimsData(res.data.data.history)
            const allData = res.data.data.history;
            const slicedData = [];
            for (let i = 0; i < allData.length; i += sliceValue) {
              slicedData.push(allData.slice(i, i + sliceValue));
            }
            setgivingCurrent(slicedData[0].length > 0 ?slicedData[0] : [])
            setgivingArray(slicedData);
          }else{
              setError(res.data.message)
              setProcessing(0)
          }
      }).catch((e) => {
          setError('There was an internal error, please try again later')
          setProcessing(0)
      })

    setFetching(0)
    },[])

      const PreviousPage = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setCurrentPage(currentPage > 0 ? currentPage-1 : currentPage)
        if(currentPage > 0){
          setgivingCurrent(givingArray[currentPage-1])
          setgivingCurrentDefault(givingArray[currentPage-1])
        }
      }
      const NextPage = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setCurrentPage(currentPage == givingArray.length-1 ? currentPage : currentPage+1)
        if(currentPage == givingArray.length-1){
      
        }else{
          setgivingCurrent(givingArray[currentPage+1])
          setgivingCurrentDefault(givingArray[currentPage+1])
        }
      }
      const numberedPage = (id) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setCurrentPage(id)
        setgivingCurrent(givingArray[id])
        setgivingCurrentDefault(givingArray[id])
      }
      const [searchItem, setSearchItem] = useState("");
      //Active donation
      const [activeDonation, setActiveDonation] = useState()
      const changeActiveDon = (id) => {
        if(activeDonation == id) setActiveDonation(null)
        else setActiveDonation(id)
      }
      
      //giving types
      const givingTypes = ['Cash','Individual','Aggregated','GASDS']
      
      //filter
      const [filterr, setFilterr] = useState()
      const filterAction = () => {
        if(filterr) setFilterr(0)
        else setFilterr(1)
      }

    const [singleHistory, setSingleHistory] = useState()

    function changeToSingle(){
        setSingleHistory(1)
    }
  return (
        <>
        {!singleHistory ? 
        <>
          {/* <div className='col-span-1 flex lg:flex-row flex-col space-y-4 lg:space-y-0 justify-between mt-5 lg:items-center text-start'>
            <div className='flex flex-row space-x-4'>
                <div className='relative'>
                <div className='absolute top-3 z-40 left-4'><img src={search} /></div>
                <input onChange={(e) => setSearchItem(e.target.value)} className='relative w-[321px] bg-[#F7F7F7] h-[40px] pl-10 rounded-[8px] border-[1px] text-[#8A8A8A] outline-none' placeholder='Search' />
              </div>
            </div>
            <div className='flex lg:flex-row flex-col space-y-4 lg:space-y-0 lg:space-x-3 lg:items-center'>
              <div className=''>
                <input className='border-[1px] h-[40px] w-full lg:w-[200px] rounded-[4px] outline-none border-[#D9D9D9] p-1' type='date' />
              </div>
              <div>
              <div onClick={() => filterAction()} className='flex flex-row space-x-3 border-[1px] rounded-[4px] p-1 px-3 h-[40px] cursor-pointer'>
                <img src={filter} />  
                <span>Filter</span>
              </div>
              {filterr ?
              <div className='absolute rounded-[4px] flex flex-col z-50 bg-white space-y-3 shadow-md right-10 py-2'>
                {givingTypes.map((item,id) => {
                  return (
                    <div className='flex flex-row space-x-2 px-4 cursor-pointer hover:bg-[#F7F7F7] py-2'>
                      <input type='checkbox' className='accent-[#B30000]'/>
                      <p>{item}</p>
                    </div>
                  )
                })}
              </div>
              : null}
            </div>
            </div>
          </div> */}
          <div className='col-span-1 mt-5 border-[1px] rounded-[8px]'>
            <table className="table-auto overflow-x-scroll w-full block divide-y divide-gray-200 ">
              <thead className='bg-[#F7F7F7]'>
                  <tr className=''>
                  <th className='px-8 py-3 text-start text-[#292D32] font-[400] w-[16.67%]'>S/N</th>
                  <th className='px-8 py-1 text-start text-[#292D32] font-[400] w-[16.67%]'>Year</th>
                  <th className='px-10 py-1 text-start text-[#292D32] font-[400] w-[16.67%]'>Start Date</th>
                  <th className='px-10 py-1 text-start text-[#292D32] font-[400] w-[16.67%]'>Submission Date</th>
                  <th className='px-10 py-1 text-start text-[#292D32] font-[400] w-[16.67%]'>Total Giving</th>
                  <th className='px-10 py-1 text-start text-[#292D32] font-[400] w-[16.67%]'>Status</th>
                  </tr>
              </thead>
              <tbody className='divide-y divide-gray-200'>
                  {givingCurrent.filter((row) =>
                  !searchItem.length || row.name
                  .toString()
                  .toLowerCase()
                  .includes(searchItem.toString().toLowerCase()) 
                  ).map(({id,name,member_id,date,total,type,payment,email,last_donation,year,start_date,submission,status},index) => {
                      return (
                          <tr onClick={changeToSingle} className='cursor-pointer' key={id}>
                              <td className='px-8 py-3 text-star'>{index+1}</td>
                              <td className='px-8 py-3 text-start text-[#4F4F4F]'>{year}</td>
                              <td className='px-10 py-3 text-start text-[#4F4F4F]'>{start_date}</td>
                              <td className='px-10 py-3 text-start text-[#4F4F4F]'>{submission}</td>
                              <td className='px-10 py-3 text-start text-[#4F4F4F]'>£{total.toLocaleString()}</td>
                              <td className='px-10 py-3 text-start text-[#4F4F4F]'>
                                <span className={`${status == 0 ? 'bg-[#F6F0EB] text-[#F2994A]' : 'bg-[#E9F0EC] text-[#219653]'} p-2 rounded-[30px]`}>{status == 0 ? 'Pending' : 'Submitted'}</span>
                            </td>
                          </tr>
                          )
                      }) 
                  }
              </tbody>
            </table>
          </div>
          <div className='col-span-1 flex flex-row justify-between items-center'>
            {searchItem? '' : <span className='text-[#333333] text-[16px]'>Showing <span>{givingCurrent.length}</span>/<span>{claimsData.length}</span> results</span>}
            {fetching ? 
              <div className='col-span-8 lg:col-span-6 flex flex-col justify-center items-center '>
                  <ThreeDots height="80" width="80" radius="9" color="#333333" ariaLabel="three-dots-loading"
                  wrapperStyle={{}} wrapperClassName="" visible={true}
                  />
              </div> 
              :
            <div className={`ml-auto grid grid-cols-6 gap-x-4`}>
                  <div className={`mt-5 col-span-6 flex flex-row gap-x-3 mb-5 lg:ml-auto`}>
                      <div onClick={PreviousPage} className={`border-[#BCBCBC] h-[40px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto border-[1px] px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center text-[#BCBCBC] hover:text-[#1D242D] cursor-pointer`}>{`<`}</div>
                      {givingArray.slice(0,5).map((givingArray,index)=> {
                          return (
                              <div key={index} onClick={()=>numberedPage(index)} className={`transitiion-all h-[40px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto text-start duration-500 border-[#BCBCBC] font-[600] text-[18px] border-[1px] px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center ${currentPage == index ? 'text-white bg-[#B30000] border-[#B30000] hover:bg-[#B30000] hover:text-white' :'text-[#292D32] bg-white hover:text-white hover:bg-[#B30000]'} hover:text-[#1D242D] cursor-pointer`}>{index+1}</div>
                          )
                      })}
                      <div onClick={NextPage} className={`border-[#BCBCBC] border-[1px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center text-[#BCBCBC] hover:text-[#1D242D] cursor-pointer`}>{`>`}</div>
                  </div>
              </div>
            }
          </div>
          </> 
          : 
          <HistorySingle></HistorySingle>
        }
    </>
  )
}

export default ClaimHistory