import React, { useEffect, useState } from "react";
import General from "./general";
import Roles from "./roles";
import Security from "./security";
import Billings from "./billing";
import Giving from "./giving";

function Settings() {
  const pages = sessionStorage.getItem('role') !=1 ? [
    { id: 3, title: "Security" },
  ] : [
    { id: 1, title: "General" },
    { id: 2, title: "Admins" },
    { id: 3, title: "Security" },
    { id: 4, title: "Subscription" },
    // { id: 5, title: "Giving" },
  ];
  const [aP, setAP] = useState(sessionStorage.getItem('role') != 1 ? 3 :1);

  useEffect(() =>{
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const session_id = urlParams.get('session_id');
    if (session_id) {
      setAP(4)
    } 

  }, [])
  return (
    <>
      <div className="flex flex-col text-start px-8 mt-5 space-y-4">
        <span className="text-[#121927] font-[600] text-[24px]">Settings</span>
        <div className="border-[1px] rounded-[8px] p-2 flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-3 text-center lg:text-start">
          {pages.map(({ id, title }) => {
            return (
              <div
                onClick={() => setAP(id)}
                key={id}
                className={`${
                  aP == id ? "bg-black text-white" : "text-[#4F4F4F]"
                } p-2 cursor-pointer rounded-[4px]`}
              >
                {title}
              </div>
            );
          })}
        </div>
        {aP == 1 ? (
          <General></General>
        ) : aP == 2 ? (
          <Roles></Roles>
        ) : aP == 3 ? (
          <Security></Security>
        ) : aP == 4 ? (
          <Billings></Billings>
        ) : (
          <Giving></Giving>
        )}
      </div>
    </>
  );
}

export default Settings;
