import React from "react";
import Logo from "../../images/icons/logo.svg";
import Insta from "../../images/icons/insta.svg";
import Face from "../../images/icons/fb.svg";
import Linked from "../../images/icons/link.svg";
import Mail from "../../images/icons/email-icon.svg";
import Telephone from "../../images/icons/phone-icon.svg";
import Twitter from "../../images/icons/twitter.svg";
import Address from "../../images/icons/address.svg";
import { Link} from "react-router-dom";

const Footer = () => {
  return (
    <div className="bg-[#121927]">
      <div className="px-5 lg:max-w-[1440px] lg:w-[95%] lg:m-auto py-10 lg:py-20">
        <div className="lg:flex lg:justify-between lg:items-start">
          <div className="flex-1 space-y-4">
            <img src={Logo} alt="logo" />
            <p className="text-white text-left pt-2 lg:w-[454px] font-[300]">
              Explore your very own stress-free gift-aid management cloud-based software developed to help in managing your members, donations and claim gift aid
            </p>
            <div className="flex flex-row items-center space-x-3 pt-4">
              {/* <a target="_blank" href="https://instagram.com"><img src={Insta} alt="ig" /></a> */}
              <a target="_blank" href="https://www.facebook.com/profile.php?id=100092559132222"><img src={Face} alt="fb" /></a>
              <a target="_blank" href="https://www.linkedin.com/company/chgams/"><img src={Linked} alt="lk" /></a>
              {/* <a target="_blank" href="https://twitter.com"><img src={Twitter} alt="X" /></a> */}
            </div>
          </div>
          <div className="lg:flex lg:justify-between flex-1">
            <div className="py-8 lg:py-0 space-y-4">
              <p className="text-white text-[20px] font-[600]">Company</p>
              <ul className="list-none text-white text-[16px] font-[300] pt-2 space-y-4">
                <li className="pt-2"><Link to='/'>Home</Link></li>
                <li className="pt-2"><Link to='https://blog.chgams.com'>Blog</Link></li>
                <li className="pt-2"><Link to='/support'>Support</Link></li>
              </ul>
            </div>
            <div className="space-y-4">
              <span className="text-white text-[20px] font-[600]">Contact Us</span>
              <div className="text-white text-[16px] font-[300] pt-2 space-y-4">
                <div className="flex flex-row pt-2 items-start space-x-3">
                  <img src={Mail} alt="img" />
                  <span className="text-white">info@chgams.com</span>
                </div>
                {/* <div className="flex flex-row pt-2 items-start space-x-3">
                  <img src={Telephone} alt="img" />
                  <span className="text-white">+456897456987</span>
                </div> */}
                <div className="flex flex-row pt-2 items-start space-x-3">
                  <img src={Address} alt="img" />
                  <span className="text-white w-[212px]">
                    United Kingdom
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
