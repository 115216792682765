import React, { useEffect, useState } from 'react'
import logo from '../../images/logo.svg'
import google from '../../images/icons/google.svg'
import { AiOutlineEye,AiOutlineEyeInvisible } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import welcome from '../../images/welcome.svg'
import axios from 'axios'
import settings from '../../components/getrequest'
import { LineWave } from  'react-loader-spinner'
import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider ,getAuth, signInWithPopup} from "firebase/auth";
import { ThreeCircles } from  'react-loader-spinner' 

function Login() {
  if(sessionStorage.getItem('access_token')){
    window.location.href = sessionStorage.getItem('chgamsDashUrl')
  }
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "chgams-dd62e.firebaseapp.com",
    projectId: "chgams-dd62e", 
    storageBucket: "chgams-dd62e.appspot.com",
  };
  const app = initializeApp(firebaseConfig);
  const provider = new GoogleAuthProvider();
  const auth = getAuth();
  const [googLogin, setGoogleLogin] = useState(false)
  const googelSignin = () => {
    if(googLogin){
      return
    }
    signInWithPopup(auth, provider)
    .then((result) => {
      setGoogleLogin(true)
      //console.log(result._tokenResponse.oauthIdToken)
      let datas = {id_token:result._tokenResponse.oauthIdToken}
      axios.post(process.env.REACT_APP_BASE+'/api/google-login',datas,settings)
      .then((res) => {
          if(res.data.code == 200){
            const d = new Date();
            let time = d.getTime();
            sessionStorage.setItem('login',time)
            sessionStorage.setItem('prefix',res.data.data.user.prefix)
            sessionStorage.setItem('access_token',res.data.data.access_token)
            sessionStorage.setItem('user_name',res.data.data.user.user_name)
            sessionStorage.setItem('enterprise',res.data.data.user.enterprise)
            sessionStorage.setItem('role',res.data.data.user.role)
            sessionStorage.setItem('chgamsEntImage',res.data.data.user.image)
            sessionStorage.setItem('chgamsSubscription',res.data.data.user.sub) 
            sessionStorage.setItem('chgamsDashUrl',res.data.data.user.login)
            setProcessing(0)
            if(res.data.data.user.role == 4){
              sessionStorage.setItem('member_id',res.data.data.user.member_id)
            }
            if(res.data.data.user.due == 1){
              sessionStorage.setItem('chgamsSubscriptionDue',1)
            }
            window.location.href=res.data.data.user.login
          }else{
              setGoogleLogin(false)
              setError(res.data.message)
              setProcessing(0)
          }
          
      }).catch((e) => {
        setGoogleLogin(false)
          setError('There was an internal error, please try again later')
          setProcessing(0)
      })
    }).catch((error) => {
      setGoogleLogin(false)
      setError('There was an internal error, please try again later')
      setProcessing(0)
    });
  }
  const [pass2,setPass2] = useState()
  const [loginData,setLoginData] = useState({})
  const [error, setError] = useState(0)
  const [error2, setError2] = useState(0)
  const [processing, setProcessing] = useState(0)
  const [validate, setValidate] = useState(0)

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

  const [resend, setResend] = useState()
  const [sec, setSec] = useState(20)
  const emailLogin = () => {
    if(!loginData.email || !loginData.password || loginData.password.length < 8 ){
      setError('Please fill all fields correctly')
    }else{
      if(!isValidEmail(loginData.email)){
        setError('Invalid email')
        return
      }
      setProcessing(1)
      let datas = loginData
      axios.post(process.env.REACT_APP_BASE+'/api/login',datas,settings)
      .then((res) => {
          if(res.data.code == 200){
            setError(0)
            if(res.data.message == 'validate'){
              setSec(20)
              setResend(0)
              setValidate(1);
              if(!sessionStorage.getItem('makeResend')){
                alert('You are about to login as a Super Admin, please check your email for an OTP and input to continue')
              }
            }else{
              const d = new Date();
              let time = d.getTime(); 
              sessionStorage.setItem('login',time)
              sessionStorage.setItem('prefix',res.data.data.user.prefix)
              sessionStorage.setItem('access_token',res.data.data.access_token)
              sessionStorage.setItem('user_name',res.data.data.user.user_name)
              sessionStorage.setItem('user_first_name',res.data.data.user.first_name)
              sessionStorage.setItem('user_last_name',res.data.data.user.last_name)
              sessionStorage.setItem('user_email',res.data.data.user.email)
              sessionStorage.setItem('enterprise',res.data.data.user.enterprise)
              sessionStorage.setItem('role',res.data.data.user.role)
              sessionStorage.setItem('chgamsEntImage',res.data.data.user.image)
              sessionStorage.setItem('chgamsSubscription',res.data.data.user.sub)
              sessionStorage.setItem('chgamsDashUrl',res.data.data.user.login)
              if(res.data.data.user.role == 4){
                sessionStorage.setItem('member_id',res.data.data.user.member_id)
              }
              if(res.data.data.user.due == 1){
                sessionStorage.setItem('chgamsSubscriptionDue',1)
              }
              window.location.href=res.data.data.user.login
            }
          }else{
              setError(res.data.message)
          }
          setProcessing(0)
      }).catch((e) => {
          setError('There was an internal error, please try again later')
          setProcessing(0)
      }) 
    }
  }



  useEffect(() => {
      let countdown;
      if (sec > 0) {
        countdown = setInterval(() => {
          setSec((prevTimer) => prevTimer - 1);
        }, 1000);
      } else {
        setResend(1);
      }
      return () => {
        clearInterval(countdown);
      };
    }, [sec]);

    const ResendOtp = () => {
      if(sec != 0){
          return
      }else{
        loginData.otp = null
        sessionStorage.setItem('makeResend',1)
        emailLogin()
      }
    }

  const forgotPassword = () => {
    if(!loginData.email ){
      setError2('Please fill your email and try again')
    }else{
      if(!isValidEmail(loginData.email)){
        setError('Invalid email')
        return
      }
      setProcessing(1)
      let datas = {email:loginData.email}
      axios.post(process.env.REACT_APP_BASE+'/api/password/forgot',datas,settings)
      .then((res) => {
          if(res.data.code == 200){
            alert(res.data.message)
            setError2(0)
          }else{
            setError2(res.data.message)
          }
          setProcessing(0)
      }).catch((e) => {
          setError2('There was an internal error, please try again later')
          setProcessing(0)
      })
    }
  }

  const setData = (val,field) => {
    setLoginData({
      ...loginData, [field] : val
    })
    console.log(val)
    if(field == 'password') localStorage.chgamspassword = val
    else localStorage.chgamsemail = val
  }
  useEffect(() => {
    if(localStorage.chgamscheckbox){
      document.getElementById('checkbox').checked =  true
      setLoginData({
        ...loginData, email:localStorage.chgamsemail,password:localStorage.chgamspassword 
      })
    }
    
  },[])
  
  function RememberMe(e) {
    //console.log(e.target.checked)
    if (e.target.checked && document.getElementById('email').value !== "" && document.getElementById('password').value !== "") {
      localStorage.chgamsemail = document.getElementById('email').value;
      localStorage.chgamspassword = document.getElementById('password').value;
      localStorage.chgamscheckbox = document.getElementById('checkbox').value;
    } else {
      localStorage.chgamsemail = "";
      localStorage.chgamspassword = "";
      localStorage.removeItem("chgamscheckbox");
    }
  }
  return (
    <>

        <div className={`grid grid-cols-2 ${googLogin ? 'opacity-5' : ''}`}>
          <div className='col-span-2 lg:col-span-1 px-5 lg:px-20 flex flex-col space-y-10 pt-5'>
            {googLogin ? <div ><img src={logo} /></div> : <Link to='/'><img src={logo} /></Link>}
            <div className='flex flex-col space-y-8 text-start'>
              <div className='flex flex-col space-y-'>
                <span className='text-[#090712] text-[32px] font-[500]'>Welcome</span>
                <span className='text-[#828282] text-[16px] font-[400]'>We are happy to have you back. Login with your details.</span>
              </div>
              <div onClick={googelSignin} className='border-[1px] rounded-[8px] flex flex-row items-center justify-center space-x-2 h-[50px] cursor-pointer'>
                <span><img src={google} /></span>
                <span className='text-[#4F4F4F] text-[16px]'>Login with Google</span>
              </div>
              <div className='flex flex-row space-x-4 items-center'>
                <span className='border-b-[1px] w-full'></span>
                <span className='text-[#828282] text-[16px] font-[400]'>Or</span>
                <span className='border-b-[1px] w-full'></span>
              </div>
              <div className='grid grid-cols-2 text-[#505050] text-[16px] text-start gap-x-4 gap-y-5'>
                    <div className='col-span-2 flex flex-col space-y-2 mx-auto w-full'>
                        <label>Email Address</label>
                        <div className='w-full relative'>
                            <input onChange={(e) => setData(e.target.value,'email')} id="email" name="email" type='email' className='border-[1px] rounded-[4px] w-full h-[50px] px-3 outline-none' defaultValue={localStorage.chgamscheckbox ? localStorage.chgamsemail : ''} placeholder='Enter email'/>
                            {error2 ? <span className='text-[#EB5757] text-[13px] font-[500]'>{error2}</span> : ''}
                        </div>
                    </div>
                    <div className='col-span-2 flex flex-col space-y-2 mx-auto w-full'>
                        <div className='flex flex-row justify-between'>
                          <label>Password</label>
                          <button disabled={googLogin} onClick={forgotPassword} className='text-[14px] text-[#B30000]'>Forgot Password</button>
                        </div>
                        <div className='w-full relative'>
                            <input onChange={(e) => setData(e.target.value,'password')} id="password" name="password" type={pass2 ? 'text' : 'password'} className='border-[1px] rounded-[4px] w-full h-[50px] px-3 outline-none' defaultValue={localStorage.chgamscheckbox ? localStorage.chgamspassword : ''} placeholder='Input your password'/>
                            <span onClick={()=>setPass2(!pass2)} className='absolute right-3 top-4 cursor-pointer '>{pass2 ? <AiOutlineEye/> : <AiOutlineEyeInvisible/>}</span>
                            {error ? <span className='text-[#EB5757] text-[13px] font-[500]'>{error}</span> : ''}
                        </div>
                        {validate ?
                        <div className='col-span-2 flex flex-col space-y-2 mx-auto w-full pt-3'>
                            <label>2FA OTP</label>
                            <div className='w-full relative'>
                                <input onChange={(e) => setData(e.target.value,'otp')}  type='text' className='border-[1px] rounded-[4px] w-full h-[50px] px-3 outline-none' placeholder='Enter otp'/>
                                <div className='text-right text-[14px] text-[#0E0E0E] pt-1'>Did not receive OTP? <span onClick={ResendOtp} className={` ${resend? '':'opacity-50'} text-[#B30000] cursor-pointer`}>{resend ? 'Resend now' : 'Resend in '+sec+' secs.'}</span></div>
                            </div>
                        </div>
                        :null}
                        <div className='w-full flex flex-row space-x-3 pt-5'>
                            <input disabled={googLogin} onChange={RememberMe} id="checkbox" name="checkbox" type='checkbox' className='accent-[#B30000]'/>
                            <label>Remember Information</label>
                        </div>
                        {processing ? <span className='flex flex-row items-center'><LineWave height="100" width="100" color="#B30000" ariaLabel="line-wave" wrapperStyle={{marginTop:'-40px'}}
                                wrapperClass="" visible={true} firstLineColor="" middleLineColor="" lastLineColor=""/> <span className='font-[500]'>Please wait</span></span>
                                : <div className='w-full pt-5'><button disabled={googLogin} onClick={emailLogin} className='bg-[#B30000] text-white w-full h-[50px] rounded-[4px]'>Login</button></div> }
                        <div className='pt-3'>Do not have an account ? {googLogin ? <span className='text-[#B30000]'>Create Account</span> : <Link to='/onboard' disabled={googLogin} className='text-[#B30000]'>Create Account</Link> }</div>
                    </div>
                </div>
            </div>
          </div>
          <div className='col-span-1 pl-5 hidden lg:flex lg:pl-20 bg-[#F3F5F7] flex flex-col text-start pt-20'>
            <span className='text-[40px] font-[600]'>Welcome to Chgams</span>
            <span className='text-[#4F4F4F] pr-5'>We help empowers charity organizations to efficiently register and manage all activities of their members.</span>
            <div className='ml-[-40px]'><img src={welcome} /></div>
          </div>
        </div>
        {googLogin ? 
        <div className='absolute top-60 justify-center flex w-full'>
          <ThreeCircles
                  height="100"
                  width="100"
                  color="#B30000"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="three-circles-rotating"
                  outerCircleColor=""
                  innerCircleColor=""
                  middleCircleColor=""
                />  
        </div> : null }
    </>
  )
}

export default Login