import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';

function Doc() {

 let menu = [
    {'id':1,'title':'Introduction'},
    {'id':2,'title':'Key Features'},
    {'id':3,'title':'Plans'},
    {'id':4,'title':'Onboarding'},
    {'id':5,'title':'Dashboard'},
    {'id':6,'title':'Members'},
    {'id':7,'title':'Givings'},
    {'id':8,'title':'Groups'},
    {'id':9,'title':'HMRC Claims'},
    {'id':10,'title':'Support'},
    {'id':11,'title':'Conclusion'},
  ];
  const [activeMenu, setActiveMenu] = useState('Introduction');
  const switchActive = (id,title) => {
    document.documentElement.style.scrollBehavior = "smooth";
    setActiveMenu(title)
    const element = document.getElementById(title);
    element.scrollIntoView();
  }

  const handleScroll = () => {
    // Logic to identify the active section based on scroll position
    const sectionElements = document.querySelectorAll('.documentation-section');
    
    sectionElements.forEach((section) => {
      const rect = section.getBoundingClientRect();
      if (rect.top <= window.innerHeight * 0.5 && rect.bottom >= window.innerHeight * 0.5) {
        // Section is approximately in the middle of the viewport
        const sectionId = section.getAttribute('id');
        setActiveMenu(sectionId);
        if (sectionId !== activeMenu) {
          setActiveMenu(sectionId);
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  })

  return (
    <div className='grid grid-cols-8 px-5 lg:px-20 lg:pt-10 pb-20 '>
      <div className='col-span-2 hidden lg:block sticky top-0 w-full h-screen overflow-y-auto absolute'>
        <span className='text-[#333333] text-[18px] font-[600]'>CHGAMS DOCUMENTATION</span>
        <div className='flex flex-row mt-8'>
          <div className='w-[1px] bg-[#A5A5A5]'></div>
          <div className='flex flex-col'>
          {menu.map(({id,title}) => {
            return(
              <div className='flex flex-row space-x-5 cursor-pointer' onClick={() => switchActive(id,title)}>
                  <div key={id} className={`w-[1px] ml-[-1px] ${activeMenu == title ? 'bg-[#B30000]' : null}`}></div>
                  <div className={`${activeMenu == title ? 'text-[#B30000]' : 'text-[#A5A5A5]'} text-[14px] ${title != 'Introduction' ? 'pt-4' : null} ${title == 'Conclusion' ? 'pb-10 mb-5' : null}`}>{title}</div>
                </div>
            )
          })}
          </div>
        </div>
      </div>
      <div className='col-span-8 lg:col-span-6 mt-10 flex flex-col space-y-8'>
        <div className='lg:hidden font-[600] text-[20px]'>CHGAMS DOCUMENTATION</div>
          <div className='flex flex-col documentation-section' id="Introduction">
            <div className='text-[#0E0E0E] font-[600] text-[20px]'>Introduction</div>
            <div className='text-[#4F4F4F] text-[16px] mt-3 space-y-2 font-[300]'>
              <p>Thank you for selecting Chgams, the state-of-the-art Software as a Service (SAAS) solution dedicated to elevating the capabilities of charitable organizations and simplifying the journey of making a positive impact. This documentation serves as your comprehensive guide to understanding and optimizing the full potential of Chgams.</p>
              <p>Chgams goes beyond being just software; it's a dynamic force for positive change. We recognize the distinctive challenges faced by charities today and have designed Chgams with a singular mission – to empower organizations like yours with the tools and insights needed to thrive.</p>
              <p>Whether you're a seasoned Chgams user or just embarking on this journey, our documentation is crafted to guide you through every aspect of the platform. From initial setup to advanced features, find comprehensive guides, tutorials, and FAQs to ensure your success.</p>
              <p>Our commitment to your success extends beyond this documentation. Explore our support channels and join our vibrant community to connect with fellow users, share best practices, and stay updated on the latest features and enhancements.</p>
              <p>Whether you're a seasoned Chgams user or just embarking on this journey, our documentation is crafted to guide you through every aspect of the platform. From initial setup to advanced features, find comprehensive guides, tutorials, and FAQs to ensure your success.</p>
              <p>Our commitment to your success extends beyond this documentation. Explore our support channels and join our vibrant community to connect with fellow users, share best practices, and stay updated on the latest features and enhancements.</p>
            </div>
          </div>

          <div className='flex flex-col documentation-section' id="Key Features">
            <div className='text-[#0E0E0E] font-[600] text-[20px]'>Key Features</div>
            <div className='text-[#4F4F4F] text-[16px] mt-3 space-y-2 font-[300]'>
              <ul className='list-disc ml-5 space-y-2'>
                <li>
                  <p className='font-semibold'>User-friendly dashboard</p>
                  <p>Our platform offers an intuitive and easily navigable dashboard designed to provide a smooth and efficient user experience.</p>
                </li>
                <li>
                  <p className='font-semibold'>Member registration and profile management</p>
                  <p>Easily register as a member and manage your profile effortlessly, ensuring a personalized and convenient experience tailored to your needs.</p>
                </li>
                <li>
                  <p className='font-semibold'>Easy donation tracking</p>
                  <p>Seamlessly track and manage donations with simplicity and clarity, allowing you to monitor and organize contributions effortlessly.</p>
                </li>
                <li>
                  <p className='font-semibold'>Group creation and management</p>
                  <p>Create and manage groups efficiently, enabling effective collaboration and streamlined communication among members.</p>
                </li>
                <li>
                  <p className='font-semibold'>Seamless HMRC claims submission</p>
                  <p>Effortlessly submit claims to HMRC (His Majesty's Revenue and Customs) without hassle, ensuring a smooth and efficient process for tax-related submissions..</p>
                </li>
              </ul>
            </div>
          </div>

          <div className='flex flex-col documentation-section' id="Plans">
            <div className='text-[#0E0E0E] font-[600] text-[20px]'>Plans</div>
            <div className='text-[#4F4F4F] text-[16px] mt-3 space-y-2'>
            <ul className='list-disc ml-5 space-y-2 font-[300]'>
                <li className='space-y-1'>
                  <p className='font-semibold'>Trial Plan</p>
                  <p>Our trial plan is an opportunity for you to experience the full spectrum of our system's capabilities. During this trial, you'll have access to all features, including donation recording, group creation, and membership management. This hands-on experience allows you to explore the system's functionality and witness its efficiency in action.</p>
                  <p>However, please note that the trial period spans three months. Once this period concludes, continued access to our SaaS platform will require an upgrade to a paid subscription. This ensures uninterrupted usage and access to ongoing support, advanced features, and the full suite of services we offer.</p>
                </li>
                <li className='space-y-1'>
                  <p className='font-semibold'>Premium Plan</p>
                  <p>The premium plan is the pinnacle of our offerings, granting you access to an extensive array of features that encompass the entirety of our platform's capabilities. In addition to a comprehensive suite of functionalities, this plan empowers you to seamlessly navigate and utilize every facet of our system.</p>
                  <p>One standout feature of the premium plan is its inclusion of HMRC tax claim capabilities. This functionality streamlines the process of submitting claims to Her Majesty's Revenue and Customs. With this feature at your disposal, you can efficiently handle tax-related submissions within our platform, ensuring accuracy, compliance, and ease of process.</p>
                  <p>Moreover, the premium plan ensures that you're equipped with all the tools necessary to manage your donations, groups, memberships, and more, consolidating these functions into a single, cohesive experience.</p>
                </li>
              </ul>
            </div>
          </div>

          <div className='flex flex-col documentation-section' id="Onboarding">
            <div className='text-[#0E0E0E] font-[600] text-[20px]'>Onboarding</div>
            <div className='text-[#4F4F4F] text-[16px] mt-3 space-y-2'>
            <ul className='list-disc ml-5 space-y-2 font-[300]'>
                <li className='space-y-1'>
                  <p className='font-semibold'>Account Creation</p>
                  <p>The first step in creating your account is providing the details you registered with HMRC. This is so you can directly make claims from our platform. The details required are your Charity Name, Charity Reference Number, Charity Registration Number, Charity Registration Body (England, Ireland, Scottish) most of which can be retrieved from your HMRC account profile. You will not be able to make a claim if any of the stated details are not correct.</p>
                </li>
                <li className='space-y-1'>
                  <p className='font-semibold'>Financial Year</p>
                  <p>The period you define as the start and end of your organization's operations in the year.Input your Financial Year; this helps us to calculate when you can make claims</p>
                </li>
                <li className='space-y-1'>
                  <p className='font-semibold'>Admin</p>
                  <p>Then we would require the details of whoever is creating the account as Admin details so claims can be easily authorized and the system can be managed</p>
                </li>
                <li className='space-y-1'>
                  <p className='font-semibold'>Plan</p>
                  <p>Last Step in your onboarding is to select your plan (refer to the plans information above)</p>
                </li>
              </ul>
            </div>
          </div>

          <div className='flex flex-col documentation-section' id="Dashboard">
            <div className='text-[#0E0E0E] font-[600] text-[20px]'>Dashboard</div>
            <div className='text-[#4F4F4F] text-[16px] mt-3 space-y-2 font-[300]'>
            The dashboard displays an overview of your performance as relating to donations and members. And you can switch between year, month and week to see details for different periods.
            </div>
          </div>

          <div className='flex flex-col documentation-section' id="Members">
            <div className='text-[#0E0E0E] font-[600] text-[20px]'>Members</div>
            <div className='text-[#4F4F4F] text-[16px] mt-3 space-y-2 font-[300]'>
              Members are regarded as the people associated with your organization particularly those who have been attached over a period of time. Members could be tax payers and non tax papers. Follow the prompts to create a new member for your organization.
            </div>
            <ul className='list-disc ml-5 space-y-2 mt-2 font-[300]'>
                <li className='space-y-1'>
                  <p className='font-semibold'>Gift Aid Declaration</p>
                  <p>creating members require you stating if claims can be made on their donations (declaring gift aid). However, this is restricted to taxpayers only, in other words, non tax payers cannot declare gift aid but they can still be registered and their donations. Claims can be made on their donations but your charity needed to have made at least 2 claims in the last four years. Refer to the HMRC portal for more information.</p>
                </li>
                <li className='space-y-1'>
                  <p className='font-semibold'>Individual Donations</p>
                  <p>These are donations recorded for tax payers. There are no limitations to this as long as your organization is validated to make claims.</p>
                </li>
              </ul>
          </div>

          <div className='flex flex-col documentation-section' id="Givings">
            <div className='text-[#0E0E0E] font-[600] text-[20px]'>Givings</div>
            <div className='text-[#4F4F4F] text-[16px] mt-3 space-y-2 font-[300]'>
            Givings are the donations received by your organization; you can record donations for members and non members. 
            </div>
            <ul className='list-disc ml-5 space-y-2 mt-2 font-[300]'>
                <li className='space-y-1'>
                  <p className='font-semibold'>Member’s Donations</p>
                  <p>All fields except ‘comment’ are required when recording donations for members. You are required to fill in the member ID created when the member was created. This can be found on the column details of your members list in your members page. When you input the member ID, you will be prompted to recheck the existence of the member; then you can confirm the member’s name and proceed with the donation record.</p>
                </li>
                <li className='space-y-1'>
                  <p className='font-semibold'>Non members donations</p>
                  <p>the member id is not required for this, all other fields are required.</p>
                </li>
                <li className='space-y-1'>
                  <p className='font-semibold'>Donations upload</p>
                  <p>You can also collate your donations in an excel / CSV file and upload for record. Your csv file must have headings (name,id,amount,type,method,date); name is the name of the member, id is the member’s id, type is the donation type (Offering, Tithe, Others), method is the payment method (Bank Transfer, POS, Cash) and date is the payment date of the donation.</p>
                </li>
              </ul>
          </div>

          <div className='flex flex-col documentation-section' id="Groups">
            <div className='text-[#0E0E0E] font-[600] text-[20px]'>Groups</div>
            <div className='text-[#4F4F4F] text-[16px] mt-3 space-y-2 font-[300]'>
              You can create groups in your organization and attach members to them. Then, you can manage the groups and donations made by the members in the group.
            </div>
          </div>

          <div className='flex flex-col documentation-section' id="HMRC Claims">
            <div className='text-[#0E0E0E] font-[600] text-[20px]'>HMRC Claims</div>
            <div className='text-[#4F4F4F] text-[16px] mt-3 space-y-2 font-[300]'>
              This is one of the most important modules of our system; it affords the ability to make tax refunds claims on donations made to your organization. This would be much possible on our premium plan. You will be directed by prompts on what to do when you click the “Submit Claim To HMRC” button.
            </div>
            <ul className='list-disc ml-5 space-y-2 mt-2 font-[300]'>
                <li className='space-y-1'>
                  <p className='font-semibold'>Correct Details</p>
                  <p>Ensure your HRMC details are correct before making claims (You can confirm this in your settings)</p>
                </li>
                <li className='space-y-1'>
                  <p className='font-semibold'>Limits</p>
                  <p>Ensure you have at least one member and donation before making a claim.</p>
                </li>
                <li className='space-y-1'>
                  <p className='font-semibold'>Running, History, Draft</p>
                  <p>Claims are categorized into running (donations you can make claims on at the momment), history (Claims you have made), draft (Claims you started making).Your claims will be registered as a draft if it is unable to be processed in the first trial, so you can always retry by going to your claims draft.</p>
                </li>
              </ul>
              <div className='text-[#4F4F4F] text-[16px] mt-3 space-y-2'>
              The claims module also records your past claims and draft for your overview.
              </div>
          </div>

          <div className='flex flex-col documentation-section' id="Support">
            <div className='text-[#0E0E0E] font-[600] text-[20px]'>Support</div>
            <div className='text-[#4F4F4F] text-[16px] mt-3 space-y-2 font-[300]'>
              To clear your concerns or get more support, please find relevant access below.
            </div>
            <ul className='list-disc ml-5 space-y-2 mt-2 font-[300]'>
                <li className='space-y-1'>
                  <p className='font-semibold'>Reach Out</p>
                  <p>Reach out to us to get support <a href='/support' className='text-[#B30000]'>here</a></p>
                </li>
                <li className='space-y-1'>
                  <p className='font-semibold'>FAQs</p>
                  <p>Find commonly asked questions <a href='/#faqs' className='text-[#B30000]'>here</a></p>
                </li>
              </ul>
          </div>

          <div className='flex flex-col documentation-section' id="Conclusion">
            <div className='text-[#0E0E0E] font-[600] text-[20px]'>Conclusion</div>
            <div className='text-[#4F4F4F] text-[16px] mt-3 space-y-2 font-[300]'>
            Thank you for choosing the Charity Management System. We hope this documentation helps you make the most of our platform. Your feedback is valuable for continuous improvement.
            </div>
          </div>

      </div>
    </div>
  )
}

export default Doc