import React, { useState } from 'react'
import back from "../../../images/icons/back.svg"
import upload from "../../../images/icons/upload.svg"
import file from "../../../images/icons/file.svg"
import delet from "../../../images/icons/delete.svg"
import remove from "../../../images/icons/remove.svg"
import Papa from "papaparse"
import { Link } from 'react-router-dom'
import success from "../../../images/success.svg"
import axios from 'axios'
import { LineWave } from  'react-loader-spinner'
import settings from '../../../components/getrequest'
import { format } from 'date-fns';

function AddGiving() {
    const [base, setBase] = useState(sessionStorage.getItem('prefix') ? sessionStorage.getItem('prefix'): 'user')
    let urlbase = process.env.REACT_APP_BASE;
    const [givingadded, setGivingAdded] = useState()
    const processGiving = () => {
        setGivingAdded(1)
      }
    const InputGiving = () => { 
        const [givingData, setGivingData] = useState({})
        const [givingType, setGivingType] = useState()
        const [makeCheck, setMakeCheck] = useState()
        const AddCheckMember = (field,value) => {
            setGivingData({...givingData, [field]:value})
            if(givingData.member_id){
                setMakeCheck(1)
            }else{
                setMakeCheck(1)
            }
        }
        const AddGivingData = (field,value) => {
            setGivingData({...givingData, [field]:value})
          }
        const [addingProcessing,setAddingProcessing] = useState()
        const [addingError,setAddingError] = useState()
        const PerformAdd = (e) => {
            setAddingProcessing(1)
            e.preventDefault();
            axios.post(urlbase+'/api/givings/create',givingData,settings())
            .then((res) => {
                if(res.data.code == 200){
                    setGivingAdded(1)
                }else{
                    alert(res.data.message);
                }
                setAddingProcessing(0)
            }).catch(() => {
                //setAddingError('There was an error, please try again')
                alert('There was an error, please try again');
                setAddingProcessing(0)
            })
        }
        const [memberName, setMemberName] = useState()
        const CheckMember = () => {
            setAddingProcessing(1)
            axios.get(urlbase+'/api/members/check?id='+givingData.member_id,settings())
            .then((res) => {
                if(res.data.code == 200){
                    setMemberName(res.data.data.name)
                }else{
                    setMemberName('')
                    alert(res.data.message);
                }
                setAddingProcessing(0)
            }).catch(() => {
                //setAddingError('There was an error, please try again')
                alert('There was an error, please try again');
                setAddingProcessing(0)
            })
        }
        const today = new Date().toISOString().split('T')[0];
        return (
            <>
                <form className='grid grid-cols-6 gap-x-3 gap-y-6 mb-10'>
                    <div className='col-span-6 lg:col-span-2 flex flex-col space-y-2 text-[#505050]'>
                        <div className='flex flex-row space-x-2 items-center'>
                            <label className=''>Member ID </label>
                            <label className='cursor-pointer' title='This is not required for GASDS givings or members that have not declared gift aids'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#B30000" class="bi bi-info-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                </svg>
                            </label>
                        </div>

                        <div className='flex flex-col'>
                            <input required onChange={(e) => AddCheckMember('member_id',e.target.value)} className='rounded-[8px] border-[1px] p-3 outline-none' placeholder='Enter member’s ID'></input>
                            {makeCheck ?
                                <label className='text-[14px] text-[#B30000] text-right cursor-pointer' onClick={CheckMember}>Check member now</label>
                                : ''
                            }
                        </div>

                    </div>
                    <div className='col-span-6 lg:col-span-2 flex flex-col space-y-2 text-[#505050]'>
                        <label className=''>Member name</label>
                        <input className='rounded-[8px] border-[1px] p-3 outline-none' readOnly defaultValue={memberName}></input>
                    </div>
                    <div className='col-span-6 lg:col-span-2 flex flex-col space-y-2'>
                        <label className='text-[#9A9A9A]'>Entry officer</label>
                        <input className='rounded-[8px] border-[1px] p-3 border-[#EAEAEA] text-[#9A9A9A] outline-none' readOnly defaultValue={sessionStorage.getItem('user_name')}></input>
                    </div>
                    <div className='col-span-6 lg:col-span-2 flex flex-col space-y-2 text-[#505050]'>
                        <label className=''>Amount</label>
                        <input onChange={(e) => AddGivingData('amount',e.target.value)} className='rounded-[8px] border-[1px] p-3 outline-none' placeholder='Enter amount' type='number'></input>
                    </div>
                    <div className='col-span-6 lg:col-span-2 flex flex-col space-y-2 text-[#505050]'>
                        <label className=''>Giving type</label>
                        <select onChange={(e) => AddGivingData('type',e.target.value)} className='rounded-[8px] border-[1px] bg-white  p-3 outline-none h-[50px] '>
                            <option selected className='text-[#9A9A9A] bg-white' value={null}>Select giving type</option>
                            <option className='bg-white' value={1}>Individual(Offering)</option>
                            <option className='bg-white' value={2}>Individual(Tithe)</option>
                            <option className='bg-white' value={null}>Others</option>
                        </select>
                    </div>
                    {/* {givingType == 'individual' ? 
                    <div className='col-span-6 lg:col-span-2 flex flex-col space-y-2 text-[#505050]'>
                        <label className=''>Individual type</label>
                        <select className='rounded-[8px] border-[1px] bg-white  p-3 outline-none h-[50px] '>
                            <option selected className='text-[#9A9A9A] bg-white'>Select type of individual giving</option>
                            <option className='bg-white'>Tithe</option>
                            <option className='bg-white'>Offering</option>
                        </select>
                    </div>
                        : null} */}
                    <div className='col-span-6 lg:col-span-2 flex flex-col space-y-2 text-[#505050]'>
                        <label className=''>Payment method</label>
                        <select onChange={(e) => AddGivingData('payment_method',e.target.value)} className='rounded-[8px] border-[1px] bg-white  p-3 outline-none h-[50px] '>
                            <option selected className='text-[#9A9A9A] bg-white'>Select payment method</option>
                            <option className='bg-white' value='Bank Transfer'>Bank Transfer</option>
                            <option className='bg-white' value='Cash'>Cash</option>
                            <option className='bg-white' value='POS'>POS</option>
                        </select>
                    </div>
                    <div className='col-span-6 lg:col-span-2 flex flex-col space-y-2 text-[#505050]'>
                        <label className=''>Payment Date</label>
                        <input max={today} onChange={(e) => AddGivingData('payment_date',e.target.value)} type='date' className='rounded-[8px] border-[1px] p-3 outline-none' placeholder='Enter amount'></input>
                    </div>
                    <div className='col-span-6 flex flex-col space-y-2 text-[#505050]'>
                        <label className=''>Comment</label>
                        <textarea onChange={(e) => AddGivingData('comment',e.target.value)} type='text' className='rounded-[8px] border-[1px] p-3 outline-none h-[114px]' placeholder='Type comment here...'></textarea>
                    </div>
                    <div className='lg:col-span-6 flex flex-col space-y-2 text-[#505050]'>
                        {addingProcessing ? <LineWave height="100" width="100" color="#B30000" ariaLabel="line-wave" wrapperStyle={{marginTop:'-40px'}}
                      wrapperClass="" visible={true} firstLineColor="" middleLineColor="" lastLineColor=""/>
                     :
                        <button onClick={PerformAdd} className='bg-[#B30000] w-[142px] h-[50px] text-[18px] text-white rounded-[4px] font-[600]'>Add Giving</button>
                        }
                    </div>
                </form>
            </>
        )
    }
    
    const UploadGiving = () => {
        const [error, setError] = useState()
        const [fileChosen, setFileChosen] = useState()
        const [theFile, setTheFile] = useState({})
        const [data, dataSet] = useState()
        const [uploadedData, setUploadedData] = useState([])
        const fileChange = (e) => {
            if(!e.target.files[0]){
                setFileChosen(0)
                document.getElementById('file').value=null
                return
            }
            let file = e.target.files[0]
            if (file.type != 'text/csv') {
                setFileChosen(0)
                document.getElementById('file').value=null
                setError('Invalid file type')
            }else{
                setTheFile(file)
                setFileChosen(1)
                setError(null)
                console.log(file)
                parseCSV(e)
            }
        }
        const selectFile = () => {
            document.getElementById('file').click()
        }
        const [addingProcessing,setAddingProcessing] = useState()
        const removeFile = () => {
            document.getElementById('file').value=null
            dataSet(0)
            setTheFile(null)
            setFileChosen(0)
            setError(null)
        }
    
        const parseCSV = (e) => {
            Papa.parse(e.target.files[0], {
              header: true,
              skipEmptyLines: true,
              complete: function (results) {
                setUploadedData(results.data)
                console.log(results.data)
              },
            });
            
          };
    
          const removeGiving = () => {
            dataSet(0)
            setTheFile(null)
            setFileChosen(0)
            setError(null)
          }

          const processGiving = () => {
            try{
                setAddingProcessing(1)
                let inputed = uploadedData;
                inputed.forEach(e => {
                    let newGiving = {member_id:e.id,amount:e.amount,type:e.type == "Individual(Offering)" ? 1 :2,
                        payment_method:e.method,payment_date:e.date}
                    setAddingProcessing(1)
                    axios.post(urlbase+'/api/givings/create',newGiving,settings())
                    .then((res) => {
                        if(res.data.code == 200){
                            setGivingAdded(1)
                        }else{
                            alert(res.data.message);
                        }
                        setAddingProcessing(0)
                    }).catch(() => {
                        alert('There was an error, please try again');
                        setAddingProcessing(0)
                    })
                });

            }catch(e){
                alert('There was an error, please ensure your upload data is correct')
            }
          }
    
        return (
            <>
               {!data ?  <form className='grid grid-cols-6 gap-x-3 gap-y-6 mb-10'>
                    <div onClick={selectFile} className='col-span-6 flex flex-col space-y-2 text-[#505050] border-dashed border-[1px] flex flex-col items-center py-10 rounded-[8px] cursor-pointer'>
                        <span><img src={upload} /></span>
                        <span className='text-[16px]'>Upload your file <span className='text-[#B30000]'>here</span></span>
                        <span className='text-[#828282] text-[14px]'>CSV File</span>
                    </div>
                    {fileChosen ? <div className='col-span-6 rounded-[8px] border-[1px] flex flex-row p-3 items-center justify-between'>
                        <div className='flex flex-row items-center space-x-2'>
                            <span><img src={file} /></span>
                            <span>{theFile.name}</span>
                            {/* <span>{parseInt(theFile.size)} mb</span> */}
                        </div>
                        <span onClick={removeFile} title='remove' className='cursor-pointer'><img src={remove} /></span>
                    </div> : null}
                    {error ? <span className='text-[14px] text-[#B30000]'>{error}</span> : null}
                    <input accept='csv' onChange={fileChange} id="file" hidden type='file' className='' />
                    <div className='lg:col-span-6 flex flex-col space-y-2 text-[#505050]'>
                        <button onClick={() => dataSet(1)} disabled={!fileChosen ? true : false} className={`${fileChosen ? 'bg-[#B30000]' : 'bg-[#E0E0E0]'} w-[142px] h-[50px] text-[18px] text-white rounded-[4px] font-[600]`}>Continue</button>
                    </div>
                </form>
                : <div className='grid grid-cols-1'>
                    <div className='col-span-1 text-start'>{theFile.name}</div>
                <div className='col-span-1 border-[1px] rounded-[8px] mt-3'>
                  <table className="table-auto overflow-x-scroll w-full block divide-y divide-gray-200 ">
                    <thead className='bg-[#F7F7F7]'>
                        <tr className='px-7 py-1 text-[#292D32] font-[400]'>
                        <th className='px-6 py-1 text-start text-[#292D32] font-[400]'>Donor Name</th>
                        <th className='px-6 py-1 text-start text-[#292D32] font-[400]'>Member ID</th>
                        <th className='px-6 py-1 text-start text-[#292D32] font-[400]'>Amount</th>
                        <th className='px-6 py-1 text-start text-[#292D32] font-[400]'>Giving Type</th>
                        <th className='px-6 pr-3 py-1 text-start text-[#292D32] font-[400]'>Payment method</th>
                        <th className='px-6 pr-3 py-1 text-start text-[#292D32] font-[400]'>Payment date</th>
                        <th className='px-7 py-1 text-start text-[#292D32] font-[400]'></th>
                        </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200'>
                        {uploadedData.map(({id,name,member_id,date,amount,type,method},index) => {
                            return (
                                <tr key={index}>
                                    <td className='px-6 py-3 text-start text-[#4F4F4F]'>{name}</td>
                                    <td className='px-6 py-3 text-start text-[#4F4F4F]'>{id}</td>
                                    <td className='px-6 py-3 text-start text-[#4F4F4F]'>£{parseInt(amount).toLocaleString()}</td>
                                    <td className='px-6 py-3 text-start text-[#4F4F4F]'>{type}</td>
                                    <td className='px-6 pr-3 py-3 text-start text-[#4F4F4F]'>{method}</td>
                                    <td className='px-6 pr-3 py-3 text-start text-[#4F4F4F]'>{date}</td>
                                    <td className='px-7 py-3 text-start text-[#4F4F4F]'>
                                    <span onClick={removeGiving} className='relative cursor-pointer'><img className='' src={delet}></img></span>
                                    </td>
                                </tr>
                                )
                            }) 
                        }
                    </tbody>
                </table>
                </div>
                <div className='col-span-1 flex flex-col space-y-2 text-[#505050] mt-6'>
                    {addingProcessing ? 'Please wait' : 
                    <div className='flex flex-row space-x-3'>
                        <button onClick={() => processGiving()} className={`bg-[#B30000] w-[142px] h-[50px] text-[18px] text-white rounded-[4px] font-[600]`}>Add Giving</button>
                        <button onClick={() => dataSet(!data)} className={`bg-white border-[1px] border-[#B30000] w-[142px] h-[50px] text-[18px] text-black rounded-[4px] font-[600]`}>Cancel</button>
                    </div>

                    }
                </div>
                </div>
                }
            </>
        )
    }

//change tabs
const [tab, setTab] = useState(1)
const changeTab = (id) => {
    setTab(id)
}
  return (
    <>    {givingadded ?
        <div id="defaultModal" tabindex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
            <div className="relative w-[50%] lg:w-[48%] mt-[] max-h-full mx-auto text-[#333]">
                <div className="relative bg-white shadow pt-4 rounded-[8px] px-8">
                    <div className="flex items-start justify-between px-8">
                        <h3 className="text-[#121927] text-[20px] font-[600]">
                        </h3>
                        {/* <button onClick={() => setGivingAdded(0)} type="button" className="text-gray-300 font-[200] bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center" data-modal-hide="defaultModal">
                            <svg aria-hidden="true" className="w-5 h-5 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            <span className="sr-only">Close modal</span>
                        </button> */}
                    </div>
                    <div className='flex flex-col space-y-2 mt-3 px-5'>
                      <div className='grid mb-2'><img className='mx-auto' src={success} /></div>
                      <span className='text-[#121927] font-[600] text-[24px]'>Awesome! Donation has been successfully added</span>
                      <span className='text-[#4F4F4F] text-[16px]'>All members' donation can be found on the members page. check to see the specifics of each member's contribution.</span>
                    </div>
                    <div className="flex flex-row mt-5 space-x-3 px-8 pb-4 justify-center">
                        <Link to={'/'+base} data-modal-hide="defaultModal" type="button" className="border-[1px] w-full h-[50px] bg-white rounded-[8px] border-[1px] text-[#121927] grid my-auto text-center"><p className="my-auto">Go to Dashboard</p></Link>
                        <Link to={'/'+base+"/giving"} onClick={() => setGivingAdded(0)} data-modal-hide="defaultModal" type="button" className="border-[#828282] w-full h-[50px] rounded-[8px] bg-[#B30000] text-white grid text-center"><p className="my-auto">View Donation Details</p></Link>
                    </div>
                </div>
            </div>
        </div>
        :null}
      <div className='grid grid-cols-1 mt-5 text-start px-8'>
          <Link to={'/'+base+'/giving'} className='col-span-1 flex flex-row space-x-3'>
              <img src={back}/>
              <span className='text-[#4F4F4F] text-[16px]'>Back</span>
          </Link>
          <p className='text-[#121927] font-[600] text-[24px] mt-5'>Add giving</p>
          <div className='flex flex-row border-b-[1px] mt-10 space-x-6'>
            <span onClick={() => changeTab(1)} className={`${tab == 1 ? 'border-b-[1px] border-[#B30000] text-[#B30000]' : ''} px-2 pb-1 cursor-pointer`}>Input giving</span>
            {sessionStorage.getItem('role') !=1 && sessionStorage.getItem('role') !=2 ? null : <span onClick={() => changeTab(2)} className={`${tab == 2 ? 'border-b-[1px] border-[#B30000] text-[#B30000]' : ''} px-2 pb-1 cursor-pointer`}>Upload giving</span>}
          </div>
          <div className='mt-10'>
            {tab == 1 ? <InputGiving></InputGiving> : <UploadGiving></UploadGiving>}
          </div>
       </div>
    </>
  )


}

export default AddGiving