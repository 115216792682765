import React from 'react'
import Header from '../header'
import Hero from './hero'
import Body from './body'
import Footer from '../footer'

function HomePage() {
  return (
    <>
    <div className=''>
      <header><Header></Header></header>
      <body>
        <div><Hero></Hero></div>
        <div>
         <Body/>
        </div>
      </body>
      <footer>
        <Footer/>
      </footer>
    </div>
      
      
    </>
    
  )
}

export default HomePage