import React, {useState,useEffect} from 'react'
import search from "../../../images/icons/search.svg"
import { ThreeDots } from 'react-loader-spinner'
import axios from 'axios'
import { LineWave } from  'react-loader-spinner'
import settings from '../../../components/getrequest'
import { format } from 'date-fns';

function RunningClaims() {
  const [base, setBase] = useState(sessionStorage.getItem('prefix') ? sessionStorage.getItem('prefix'): 'user')
  let urlbase = process.env.REACT_APP_BASE;
  const [claimsTotal, setClaimsTotal] = useState(0)
  const [claimsEstimate, setClaimsEstimate] = useState(0)
  const [error, setError] = useState(0)
  const [processing, setProcessing] = useState(0)
      const [donationsData, setDonationsData] = useState([])
    const[fetching, setFetching] = useState(1)
    const sliceValue = 4
    const [givingArray, setgivingArray] = useState([])
    const[givingCurrent, setgivingCurrent] = useState([])
    const [currentPage,setCurrentPage] = useState(0)
    useEffect(()=>{
      const getClaims = async () => {
      //get running claims
      axios.get(urlbase+'/api/claims',settings())
      .then((res) => {
          if(res.data.code == 200){

            setClaimsTotal(res.data.data.total_amount)
            setClaimsEstimate(res.data.data.estimate)
            if(res.data.data.givings.length > 0){
              setDonationsData(res.data.data.givings)
              const allData = res.data.data.givings;
              const slicedData = [];
              for (let i = 0; i < allData.length; i += sliceValue) {
                slicedData.push(allData.slice(i, i + sliceValue));
              }
              setgivingCurrent(slicedData[0])
              setgivingArray(slicedData);
            }else{
              
            }

            setFetching(0)
          }else{
              setError(res.data.message)
              setProcessing(0)
              setFetching(0)
          }
      }).catch((e) => {
          setError('There was an internal error, please try again later')
          setProcessing(0)
      })
    }
    getClaims()
  },[])

      const PreviousPage = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setCurrentPage(currentPage > 0 ? currentPage-1 : currentPage)
        if(currentPage > 0){
          setgivingCurrent(givingArray[currentPage-1])
          // setgivingCurrentDefault(givingArray[currentPage-1])
        }
      }
      const NextPage = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setCurrentPage(currentPage == givingArray.length-1 ? currentPage : currentPage+1)
        if(currentPage == givingArray.length-1){
      
        }else{
          setgivingCurrent(givingArray[currentPage+1])
          // setgivingCurrentDefault(givingArray[currentPage+1])
        }
      }
      const numberedPage = (id) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setCurrentPage(id)
        setgivingCurrent(givingArray[id])
        // setgivingCurrentDefault(givingArray[id])
      }
      const [searchItem, setSearchItem] = useState("");
      //Active donation
      const [activeDonation, setActiveDonation] = useState()
      const changeActiveDon = (id) => {
        if(activeDonation == id) setActiveDonation(null)
        else setActiveDonation(id)
      }
      
      //giving types
      const givingTypes = ['Cash','Individual','Aggregated','GASDS']
      
      //filter
      const [filterr, setFilterr] = useState()
      const filterAction = () => {
        if(filterr) setFilterr(0)
        else setFilterr(1)
      }

  return (
    <>
          <div className='col-span-1 flex lg:flex-row flex-col space-y-4 lg:space-y-0 justify-between mt-5 lg:items-center text-start'>
            <div className='flex flex-row space-x-4'>
              <div className='flex flex-col items-'>
                <span className='text-[#8A8A8A] font-[400] text-[14px]'>Total Amount</span>
                <span className='text-[#121927] font-[600] text-[20px] lg:text-[28px]'>£{parseInt(claimsTotal).toLocaleString()}</span>
              </div>
              <div className='border-r-[1px]'></div>
              <div className='flex flex-col'>
                <span className='text-[#8A8A8A] font-[400] text-[14px]'>Estimated</span>
                <span className='text-[#121927] font-[600] text-[20px] lg:text-[28px]'>£{parseInt(claimsEstimate).toLocaleString()}</span>
              </div>
            </div>
            <div className='flex lg:flex-row flex-col space-y-4 lg:space-y-0 lg:space-x-4 lg:items-center'>
              <div className='relative'>
                <div className='absolute top-3 z-40 left-4'><img src={search} /></div>
                <input onChange={(e) => setSearchItem(e.target.value)} className='relative w-[280px] bg-[#F7F7F7] h-[40px] pl-10 rounded-[8px] border-[1px] text-[#8A8A8A] outline-none' placeholder='Search' />
              </div>
              {/* <div className=''>
                <input className='border-[1px] h-[40px] w-full lg:w-[200px] rounded-[4px] outline-none border-[#D9D9D9] p-1' type='date' />
              </div> */}
            </div>
          </div>
          <div className='col-span-1 mt-5 border-[1px] rounded-[8px]'>
            <table className="table-auto overflow-x-scroll w-full block divide-y divide-gray-200 ">
              <thead className='bg-[#F7F7F7]'>
                  <tr className='px-7 py-1'>
                  {/* <th className='px-7 py-3 text-start text-[#292D32] font-[400]'><input className='accent-[#B30000]' type='checkbox' /></th> */}
                  <th className='px-7 py-1 text-start text-[#292D32] font-[400] w-[20%]'>Name</th>
                  <th className='px-7 py-1 text-start text-[#292D32] font-[400] w-[20%]'>Email Address</th>
                  <th className='px-7 py-1 text-start text-[#292D32] font-[400] w-[20%]'>Member ID</th>
                  <th className='px-7 py-1 text-start text-[#292D32] font-[400] w-[20%]'>Description</th>
                  <th className='px-7 py-1 text-start text-[#292D32] font-[400] w-[20%]'>Total Giving</th>
                  <th className='px-7 py-1 text-start text-[#292D32] font-[400] w-[20%]'>Last Donation</th>
                  </tr>
              </thead>
              <tbody className='divide-y divide-gray-200'>
                  {givingCurrent.filter((row) =>
                  !searchItem.length || row.name
                  .toString()
                  .toLowerCase()
                  .includes(searchItem.toString().toLowerCase()) 
                  ).map(({id,name,member_id,total_giving,type,description,email,last_donation},index) => {
                      return (
                          <tr key={index}>
                              {/* <td className='px-7 py-3 text-star'><input className='accent-[#B30000]' type='checkbox' /></td> */}
                              <td className='px-7 py-3 text-start text-[#4F4F4F]'>{name?name:'--------'}</td>
                              <td className='px-7 py-3 text-start text-[#4F4F4F]'>{email ? email : '---------------'}</td>
                              <td className='px-7 py-3 text-start text-[#4F4F4F]'>{member_id ? member_id : '---------------'}</td>
                              <td className='px-7 py-3 text-start text-[#4F4F4F]'>{description}</td>
                              <td className='px-7 py-3 text-start text-[#4F4F4F]'>£{total_giving ? parseInt(total_giving).toLocaleString() : 0}</td>
                              <td className='px-7 py-3 text-start text-[#4F4F4F]'>{last_donation}</td>
                          </tr>
                          )
                      }) 
                  }
              </tbody>
            </table>
          </div>
          <div className='col-span-1 flex flex-row justify-between items-center'>
            {searchItem? '' : <span className='text-[#333333] text-[16px]'>Showing <span>{givingCurrent.length}</span>/<span>{donationsData.length}</span> results</span>}
            {fetching ? 
              <div className='col-span-8 lg:col-span-6 flex flex-col justify-center items-center '>
                  <ThreeDots height="80" width="80" radius="9" color="#333333" ariaLabel="three-dots-loading"
                  wrapperStyle={{}} wrapperClassName="" visible={true}
                  />
              </div> 
              :
            <div className={`ml-auto grid grid-cols-6 gap-x-4`}>
                  <div className={`mt-5 col-span-6 flex flex-row gap-x-3 mb-5 lg:ml-auto`}>
                      <div onClick={PreviousPage} className={`border-[#BCBCBC] h-[40px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto border-[1px] px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center text-[#BCBCBC] hover:text-[#1D242D] cursor-pointer`}>{`<`}</div>
                      {givingArray.slice(0,5).map((givingArray,index)=> {
                          return (
                              <div key={index} onClick={()=>numberedPage(index)} className={`transitiion-all h-[40px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto text-start duration-500 border-[#BCBCBC] font-[600] text-[18px] border-[1px] px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center ${currentPage == index ? 'text-white bg-[#B30000] border-[#B30000] hover:bg-[#B30000] hover:text-white' :'text-[#292D32] bg-white hover:text-white hover:bg-[#B30000]'} hover:text-[#1D242D] cursor-pointer`}>{index+1}</div>
                          )
                      })}
                      <div onClick={NextPage} className={`border-[#BCBCBC] border-[1px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center text-[#BCBCBC] hover:text-[#1D242D] cursor-pointer`}>{`>`}</div>
                  </div>
              </div>
            }
          </div>
    </>
  )
}

export default RunningClaims