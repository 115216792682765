import React, { useState } from 'react'
import axios from 'axios'
import { LineWave } from  'react-loader-spinner'
import settings from '../../../components/getrequest'

const Profile = () => {
  const [base, setBase] = useState(sessionStorage.getItem('prefix') ? sessionStorage.getItem('prefix'): 'user')
  let urlbase = process.env.REACT_APP_BASE;
  const [userData, setUserData] = useState()
  const [procesing, setProcessing] = useState()
  const handleInput = (e,field) => {
    setUserData({...userData, [field] : e.target.value})
  }

  const submitUpdate = () => {
    setProcessing(1)
    axios.post(urlbase+'/api/profile/update',userData,settings())
    .then((res) => {
        if(res.data.code == 200){
            alert('Update successful')
        }else{
            alert(res.data.message);
        }
        setProcessing(0)
    }).catch(() => {
        alert('There was an error, please try again');
        setProcessing(0)
    })
  }
  return (
    <>
      <div className="flex justify-between items-center">
        <div>
          <p className="text-[#121927] text-[20px] font-[600] leading-normal">Profile</p>
        </div>
        {sessionStorage.getItem('role') == 4 ? null :
        <div>
          <button className="bg-[#B30000] text-white p-2 rounded-[10px]" onClick={submitUpdate}>
            Save Changes
          </button>
        </div>}
      </div>
      {/* <div className="rounded-[10px] border border-[#EAEAEA] p-3 flex gap-x-6">
        <div>
          <img src={Pic} alt="" />
        </div>
        <div className="flex flex-col">
          <button className="bg-[#B30000] text-white p-2 rounded-[10px]">
            Upload New Photo
          </button>
          <p className="text-[#B1B5C3] font-[400] leading-[29px]">
            Image formats with max size of 3mb
          </p>
        </div>
      </div> */}
      <div className="rounded-[10px] border border-[#EAEAEA] p-3 ">
        <p className="text-[16px] font-[500] text-[#121927] leading-normal">
          Personal Information
        </p>
        <div className="w-full flex justify-between mt-4">
          <div className="col-span-2 flex flex-col space-y-2   w-[49%]">
            <label className="text-[16px] font-[400] text-[#505050] leading-normal">
              First Name
            </label>
            <div>
              <input
                type="text"
                defaultValue={sessionStorage.getItem('user_first_name')}
                placeholder="First Name"
                onChange={(e) => handleInput(e,'first_name')}
                className="border-[1px] rounded-[4px] w-full h-[50px] px-3 outline-none"
              />
            </div>
          </div>
          <div className="col-span-2 flex flex-col space-y-2  w-[49%]">
            <label className="text-[16px] font-[400] text-[#505050] leading-normal">
              Last Name
            </label>
            <div>
              <input
                type="text"
                defaultValue={sessionStorage.getItem('user_last_name')}
                placeholder="Last Name"
                onChange={(e) => handleInput(e,'last_name')}
                className="border-[1px] rounded-[4px] w-full h-[50px] px-3 outline-none"
              />
            </div>
          </div>
        </div>
        <div className="w-full flex justify-between mt-4">
          <div className="col-span-2 flex flex-col space-y-2   w-[49%]">
            <label className="text-[16px] font-[400] text-[#505050] leading-normal">
              Email Address
            </label>
            <div>
              <input
                type="email"
                defaultValue={sessionStorage.getItem('user_email')}
                placeholder="email@gmail.com"
                onChange={(e) => handleInput(e,'email')}
                className="border-[1px] rounded-[4px] w-full h-[50px] px-3 outline-none"
              />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="pb-8">
        <div className="rounded-[10px] border border-[#EAEAEA] p-3">
          <p className="text-[16px] font-[500] text-[#121927] leading-normal">
            Address
          </p>
          <div className="w-full flex justify-between mt-4">
            <div className="col-span-2 flex flex-col space-y-2   w-[49%]">
              <label className="text-[16px] font-[400] text-[#505050] leading-normal">
                Country
              </label>
              <div>
                <input
                  type="text"
                  placeholder="Enter country"
                  className="border-[1px] rounded-[4px] w-full h-[50px] px-3 outline-none"
                />
              </div>
            </div>
            <div className="col-span-2 flex flex-col space-y-2  w-[49%]">
              <label className="text-[16px] font-[400] text-[#505050] leading-normal">
                State
              </label>
              <div>
                <input
                  type="text"
                  placeholder="Enter your State"
                  className="border-[1px] rounded-[4px] w-full h-[50px] px-3 outline-none"
                />
              </div>
            </div>
          </div>
          <div className="mt-4">
            <label className="text-[16px] font-[400] text-[#505050] leading-normal">
              Address
            </label>
            <textarea
              name="address"
              className="border-[1px] rounded-[4px] w-full h-[100px] px-3 outline-none"
            ></textarea>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Profile;
