import { useEffect, useState } from "react";
import download from "../../../images/icons/download.svg";
import plus from "../../../images/icons/pluss.svg";
import Add from "../../../images/icons/add2.jpg";
import Frame from "../../../images/icons/Frame2.jpg";
import Visa from "../../../images/icons/visa.svg";
import Master from "../../../images/icons/master.svg";
import Tick from "../../../images/icons/tick-red.svg";
import axios from "axios";
import settings from "../../../components/getrequest";
import { format } from 'date-fns';

function Billings() {
  const [billingHistory, setBillingHistory] = useState([]);
  const [isProcessing, setIsProcessing] = useState()
  const [card, setCard] = useState([])
  let urlbase = process.env.REACT_APP_BASE;
  const [bill, setBill] = useState();
  const [billing, setBilling] = useState([]);
  const [activeCardIndex, setActiveCardIndex] = useState(null);
  const [holder, setHolder] = useState("")
  const [data, setData] = useState({});


const handleChange = (e,name) => {
    setData({
      ...data,
      [name]: e.target.value,
    });
};

const paymentMethods = async () =>{
  axios.get(urlbase + "/api/cards", settings())
  .then((res) =>{
    if (res.data.code === 200){
      setCard(res.data.data)
    }else {
      console.log('Error')
    }
  })
  .catch((error) => {
    console.log(error);
  })
}

const createCheckout = () => {
  setIsProcessing(1)
  axios.get(urlbase + "/api/subscriptions/checkout/"+choosePlan, settings())
  .then((res) =>{
    if (res.data.code === 200){
      window.location.href = res.data.data.url
    }else {
      console.log('Error')
    }
  })
  .catch((error) => {
    console.log(error);
    setIsProcessing(0)
  })
}
const [isRenew, setIsRenew] = useState()
const renewSubscription = () => {
  setChoosePlan(sessionStorage.getItem('chgamsSubscription'))
}

function getQueryParams() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams;
}

const confirmPayment = (session_id,pppp) => {
  axios.get(urlbase + "/api/subscriptions/confirm/"+session_id+'/'+pppp, settings())
  .then((res) =>{
    if (res.data.code === 200){
      sessionStorage.setItem('chgamsSubscription',res.data.data.sub)
      sessionStorage.removeItem('chgamsSubscriptionDue')
      alert('Subscription successful')
      window.location.href = res.data.data.url
    }else {
      alert(res.data.message)
    }
    setIsProcessing(0)
  })
  .catch((error) => {
    console.log(error);
    setIsProcessing(0)
  })
}

//alert(sessionStorage.getItem('chgamsSubscription'))

const history = async () =>{
  axios.get(urlbase + "/api/billing/history", settings())
  .then((res) =>{
    if (res.data.code === 200){
      setBillingHistory(res.data.data)

    }else {
      console.log('Error')
    }
  })
  .catch((error) => {
    console.log(error);
  })
}
useEffect(() => {
  const queryParams = getQueryParams();
  const session_id = queryParams.get('session_id');
  const check = queryParams.get('checkierr');
  if (session_id && check) {
    setIsProcessing(1)
    confirmPayment(session_id,check);
  } 
history()
paymentMethods()
}, [])


const handleClick = async () =>{
  setIsProcessing(1)
  axios
    .get(urlbase + "/api/subscriptions/cancel", settings())
    .then((res) => {
      if (res.data.code === 200) {
        sessionStorage.setItem('chgamsSubscription',1)
        alert(res.data.message)
        window.location.reload();
      } else {
        alert(res.data.message)
        console.log("Error");
      }
      setIsProcessing(0)
    })
    .catch((error) => {
      console.log(error);
      setIsProcessing(0)
    });
}

const handleCreate = async () =>{
  setIsProcessing(1)
  let datas = {'logged':1,plan:choosePlan}
  axios
    .post(urlbase + "/api/subscriptions/create",datas,settings())
    .then((res) => {
      if (res.data.code === 200) {
        sessionStorage.setItem('chgamsSubscription',res.data.data.sub)
        alert(res.data.message)
        window.location.reload();
      } else {
        alert(res.data.message)
        console.log("Error");
      }
      setIsProcessing(0)
    })
    .catch((error) => {
      alert('There was an error, please try again later')
      console.log(error);
      setIsProcessing(0)
    });
}

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(!data.cvv || !data.card_num || !data.expiry_month || !data.expiry_year){
      alert('Please fill all fields')
    } else {
      setIsProcessing(1)
      axios
        .post(urlbase + "/api/cards/create", data, settings())
        .then((res) => {
          if (res.data.code === 200) {
            alert('Card created successfully')
            window.location.reload();
          } else {
            alert(res.data.message)
            console.log("Error");
          }
          setIsProcessing(0)
        })
        .catch((error) => {
          console.log(error)
          alert('Something went wrong, please try again later')
          setIsProcessing(0)
        });
    }
  }

  const [choosePlan, setChoosePlan] = useState()
  const [cancelPlan, setCancelPlan] = useState()

  const handleDefault = async (id) => {
      setIsProcessing(1)
      axios
        .get(urlbase + "/api/cards/default/"+id,settings())
        .then((res) => {
          if (res.data.code === 200) {
            alert('Card set as default successfully')
            window.location.reload();
          } else {
            alert(res.data.message)
            console.log("Error");
          }
          setIsProcessing(0)
        })
        .catch((error) => {
          console.log(error)
          alert('Something went wrong, please try again later')
          setIsProcessing(0)
        });
  }

  return (
    <>
      {bill ? (
        <div
          id="defaultModal"
          tabIndex="-1"
          aria-hidden="true"
          className={`fixed bg-[rgba(65,_66,_88,_0.62)] rounded-[10px] top-0 bottom-0 left-0 z-[1000] w-full overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full`}
        >
          <div className="relative w-[95%] lg:w-[40%] mt-[25%] lg:mt-[10%] max-h-full mx-auto text-[#333]">
            <div className="relative bg-white shadow pt-3">
              <div className="flex items-start justify-between border-b-[1px] px-8 pb-3">
                <p className="text-[#121927] text-[20px] font-[600]">
                  Add New Payment Method
                </p>
                <button
                  onClick={() => setBill(0)}
                  type="button"
                  className="text-gray-300 font-[200] bg-transparent hover:bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center"
                  data-modal-hide="defaultModal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="p-8">
                <div className="col-span-2 flex flex-col space-y-2 py-4 mx-auto w-full">
                  <label>Card Number</label>
                  <div className="w-full relative">
                    <input
                      onChange={(e) => handleChange(e,'card_num')}
                      type="text"
                      name="card_num"
                      className="border-[1px] rounded-[4px] w-full h-[50px] px-3 outline-none"
                      placeholder="Enter Card Number"
                    />
                  </div>
                </div>
                <div className="w-full flex justify-between py-4 space-x-2">
                  <div className="col-span-2 flex flex-col space-y-2  mx-auto w-[49%]">
                    <label>Expiration year</label>
                    <div className="w-full">
                      <input
                        onChange={(e) => handleChange(e,'expiry_year')}
                        type="text"
                        name="expiry_date"
                        className="border-[1px] rounded-[4px] w-full h-[50px] px-3 outline-none"
                        placeholder="2023"
                      />
                    </div>
                  </div>
                  <div className="col-span-2 flex flex-col space-y-2 mx-auto w-[49%]">
                    <label>Expiry Month</label>
                    <div className="w-full">
                      <input
                        onChange={(e) => handleChange(e,'expiry_month')}
                        type="text"
                        name="cvv"
                        className="border-[1px] rounded-[4px] w-full h-[50px] px-3 outline-none"
                        placeholder="05"
                      />
                    </div>
                  </div>
                  <div className="col-span-2 flex flex-col space-y-2 mx-auto w-[49%]">
                    <label>CVV</label>
                    <div className="w-full">
                      <input
                        onChange={(e) => handleChange(e,'cvv')}
                        type="text"
                        name="cvv"
                        className="border-[1px] rounded-[4px] w-full h-[50px] px-3 outline-none"
                        placeholder="342"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {isProcessing ? <div>Please wait...</div> : 
              <div className="flex flex-row justify-end mt-5 space-x-2 px-8 bg-[#F7F7F7] rounded-b-[10px] py-4">
                <button
                  data-modal-hide="defaultModal"
                  type="button"
                  onClick={() => setBill(0)}
                  className="border-[1px] border-[#828282] text-[#121927] w-[102px] h-[50px] rounded-[8px] bg-white"
                >
                  Cancel
                </button>
                <button
                  data-modal-hide="defaultModal"
                  type="button"
                  onClick={handleSubmit}
                  className={`border-[1px] w-[150px] h-[50px] bg-[#B30000] rounded-[8px] border-none text-white`}
                >
                  Add New Card
                </button>
              </div>
              }
            </div>
          </div>
        </div>
      ) : null}

        {choosePlan ? (
        <div
          id="defaultModal"
          tabIndex="-1"
          aria-hidden="true"
          className={`fixed bg-[rgba(65,_66,_88,_0.62)] rounded-[10px] top-0 bottom-0 left-0 z-[1000] w-full overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full`}
        >
          <div className="relative w-[95%] lg:w-[40%] mt-[25%] lg:mt-[10%] max-h-full mx-auto text-[#333]">
            <div className="relative bg-white shadow pt-3 rounded-[8px]">
              <div className="flex items-start justify-between border-b-[1px] px-8 pb-3">
                <p className="text-[#121927] text-[20px] font-[600]">
                  Select A plan
                </p>
                <button
                  onClick={() => setChoosePlan(0)}
                  type="button"
                  className="text-gray-300 font-[200] bg-transparent hover:bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center"
                  data-modal-hide="defaultModal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <p className="p-8 pb-0 text-[20px] font-[500]">Continue with your plan or switch</p>
              <div className="p-8">
                <div className="w-full grid grid-cols-4 py-4 gap-x-4">
                  <div className="col-span-2 flex flex-col space-y-2  mx-auto mb-4 w-full">
                    <div className="w-full">
                      <input
                        onClick={(e) => setChoosePlan(3)}
                        type="text"
                        name="plan"
                        readOnly
                        className={`border-[1px] rounded-[4px] w-full h-[50px] px-3 text-center cursor-pointer outline-none ${choosePlan == 3 ? 'border-[#B30000]' : ''}`}
                        value="Silver Monthly Plan - £10"
                      />
                    </div>
                  </div>
                  <div className="col-span-2 flex flex-col space-y-2 mx-auto w-full">
                    <div className="w-full">
                      <input
                        onClick={(e) => setChoosePlan(2)}
                        type="text"
                        name="plan"
                        readOnly
                        className={`border-[1px] rounded-[4px] w-full h-[50px] px-3 text-center cursor-pointer outline-none ${choosePlan == 2 ? 'border-[#B30000]' : ''}`}
                        value="Silver Yearly Plan - £110"
                      />
                    </div>
                  </div>
                  <div className="col-span-2 flex flex-col space-y-2 mx-auto w-full">
                    <div className="w-full">
                      <input
                        onClick={(e) => setChoosePlan(4)}
                        type="text"
                        name="plan"
                        readOnly
                        className={`border-[1px] rounded-[4px] w-full h-[50px] px-3 text-center cursor-pointer outline-none ${choosePlan == 4 ? 'border-[#B30000]' : ''}`}
                        value="Gold Monthly Plan - £18"
                      />
                    </div>
                  </div>
                  <div className="col-span-2 flex flex-col space-y-2 mx-auto w-full">
                    <div className="w-full">
                      <input
                        onClick={(e) => setChoosePlan(5)}
                        type="text"
                        name="plan"
                        readOnly
                        className={`border-[1px] rounded-[4px] w-full h-[50px] px-3 text-center cursor-pointer outline-none ${choosePlan == 5 ? 'border-[#B30000]' : ''}`}
                        value="Gold Yearly Plan - £198"
                      />
                    </div>
                  </div>
                </div>

                
              </div>
              {isProcessing ? <div>Please wait...</div> : 
              <div className="flex flex-row justify-end mt-5 space-x-2 px-8 bg-[#F7F7F7] rounded-b-[10px] py-4">
                <button
                  data-modal-hide="defaultModal"
                  type="button"
                  onClick={() => setChoosePlan(0)}
                  className="border-[1px] border-[#828282] text-[#121927] w-[102px] h-[50px] rounded-[8px] bg-white"
                >
                  Cancel
                </button>
                <button
                  data-modal-hide="defaultModal"
                  type="button"
                  onClick={createCheckout}
                  className={`border-[1px] w-[150px] h-[50px] bg-[#B30000] rounded-[8px] border-none text-white`}
                >
                  Proceed
                </button>
              </div>
              }
            </div>
          </div>
        </div>
      ) : null}

        {cancelPlan ? (
        <div
          id="defaultModal"
          tabIndex="-1"
          aria-hidden="true"
          className={`fixed bg-[rgba(65,_66,_88,_0.62)] rounded-[10px] top-0 bottom-0 left-0 z-[1000] w-full overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full`}
        >
          <div className="relative w-[95%] lg:w-[40%] mt-[25%] lg:mt-[10%] max-h-full mx-auto text-[#333]">
            <div className="relative bg-white shadow pt-3">
              <div className="flex items-start justify-between border-b-[1px] px-8 pb-3">
                <p className="text-[#121927] text-[20px] font-[600]">
                  Cancel Subscription
                </p>
                <button
                  onClick={() => setCancelPlan(0)}
                  type="button"
                  className="text-gray-300 font-[200] bg-transparent hover:bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center"
                  data-modal-hide="defaultModal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="p-8">
                <div className="w-full flex justify-between py-4 space-x-2 text-[18px]">
                  Are you sure you want to cancel your subscription ?
                </div>
              </div>
              {isProcessing ? <div>Please wait...</div> : 
              <div className="flex flex-row justify-end mt-5 space-x-2 px-8 bg-[#F7F7F7] rounded-b-[10px] py-4">
                <button
                  data-modal-hide="defaultModal"
                  type="button"
                  onClick={() => setCancelPlan(0)}
                  className="border-[1px] border-[#828282] text-[#121927] w-[102px] h-[50px] rounded-[8px] bg-white"
                >
                  No
                </button>
                <button
                  data-modal-hide="defaultModal"
                  type="button"
                  onClick={handleClick}
                  className={`border-[1px] w-[150px] h-[50px] bg-[#B30000] rounded-[8px] border-none text-white`}
                >
                  Yes
                </button>
              </div>
              }
            </div>
          </div>
        </div>
      ) : null}

      <div className="border-b-[1px] pb-7 text-[#4F4F4F] md:flex md:flex-row md:justify-between md:items-center">
        <div className="flex flex-col space-y-2">
          <p className="text-[20px] font-[600] text-[#121927]">Manage Subscriptions</p>
          <p className="text-[#4F4F4F] font-[400] text-[16px]">
            Here you can view your subscription plan, cancel or view your recent billings.
          </p>
        </div>
        {isProcessing ? <span className="text-[#B30000]">Please wait..</span>:
        <div className="pt-4">
          {sessionStorage.getItem('chgamsSubscription') != 1 ?
          <button className="bg-[#FFF] py-2 px-4 text-[#121927] font-[600] rounded-[4px] border border-[#EAEAEA]" onClick={() => setCancelPlan(1)}>
            Cancel Subscription
          </button>
          :
          <button className="bg-[#FFF] py-2 px-4 text-[#121927] font-[600] rounded-[4px] border border-[#EAEAEA]" onClick={() => setChoosePlan(1)}>
            Create Subscription
          </button>
          }
        </div>}
      </div>
      <div className="lg:flex lg:flex-row md:justify-between md:items-center lg:items-start border-b-[1px] pb-7 text-[#4F4F4F]">
        <div className="flex flex-col space-y-2 pb-4 lg:flex-1">
          <p className="text-[20px] font-[600] text-[#121927]">
            Your Plan
          </p>
          <p className="text-[#4F4F4F] font-[400] text-[16px]">
            Your Current Plan is : <span className="font-[600]"> {sessionStorage.getItem('chgamsSubscription') == 1 ? 'Free - 3 Months' : sessionStorage.getItem('chgamsSubscription') == 2 ? 'Silver - £110/Year' : sessionStorage.getItem('chgamsSubscription') == 3? 'Silver - £10/Month': sessionStorage.getItem('chgamsSubscription') == 4 ? 'Gold - £18/Month' : 'Gold - £198/Year'}</span>
          </p>
        </div>
        <div>
        {sessionStorage.getItem('chgamsSubscriptionDue') == 1 && sessionStorage.getItem('chgamsSubscription') != 1 ?
          <button className="bg-[#FFF] py-2 px-4 text-[#B30000] font-[600] rounded-[4px] border border-[#B30000]" onClick={renewSubscription}>
            Renew Subscription
          </button>
          : null
          }
        </div>
      </div>
      <div className="lg:flex lg:flex-row lg:justify-between lg:items-center  text-[#4F4F4F]">
        <div className="">
          <p className="text-[20px] font-[600] text-[#121927]">
            Billing History
          </p>
        </div>
      </div>
      {billingHistory.length > 0 ?
      <div className="pb-10">
        <div className="w-full overflow-x-auto border-[1px] rounded-[8px]">
          <table className="w-full">
            <thead className="bg-[#F7F7F7] w-full rounded-[8px]">
              <tr className="text-[#292D32] font-[400]">
                {/* <th className="px-9 py-3 text-start">
                  <input type="checkbox" />
                </th> */}
                <th className="px-9 py-3 text-start">Plan</th>
                <th className="px-9 py-3 text-start">Date</th>
                <th className="px-9 py-3 text-start">Amount(£)</th>
                <th className="px-9 py-3 text-start">Status</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {billingHistory.map((item, index) => (
                <tr className="mt-3" key={index}>
                  {/* <td className="px-9 py-3 text-start text-[#4F4F4F]">
                    <input type="checkbox" />
                  </td> */}
                  <td className="px-9 py-3 text-start text-[#4F4F4F]">
                    {item.plan == 2 ? 'Silver(Yearly)' : item.plan == 3 ? 'Silver(Monthly)' : item.plan == 4 ? 'Gold(Monthly)' : item.plan == 5 ? 'Gold(Yearly)' : 'Free'}
                  </td>
                  <td className="px-9 py-3 text-start text-[#4F4F4F]">
                    {format(new Date(item.created_at),'yyyy-mm-dd')}
                  </td>
                  <td className="px-9 py-3 text-start text-[#4F4F4F]">
                    {item.amount.toLocaleString()}
                  </td>
                  <td className={`px-9 py-3 text-start text-[#4F4F4F] w-[41px] rounded-[30px] h-[17px]`}>
                    <span className={`${item.status == 0 ? 'bg-[#B30000] text-[#F4F2F2]' : 'bg-[#E9F0EC] text-[#219653]'} p-2 rounded-[30px]`}>{item.status == 0 ? 'Failed' : 'Processed'}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      : 'No billing history'}
    </>
  );
}

export default Billings;
