import React from 'react'
import { Link } from 'react-router-dom'
import arrow from '../../../images/icons/arrow-right.svg'
import hero from '../../../images/heroimage.svg'

function Hero() {
  const freeTrials = () => {
    sessionStorage.setItem('chGamsFreeTrial',1)
    window.location.href="/onboard";
  }
  return (
    <>
        <div className="elfsight-app-916e70aa-cf19-47f4-8fdc-c234f74d2c69" data-elfsight-app-lazy></div>
        <div className="bg-[url('images/homehero.svg')] flex flex-col items-center mt-8 space-y-7 mb-10 overflow-x-none">
            <button onClick={freeTrials} data-wow-duration="1s" className='rounded-[48px] wow fadeInUp cursor-pointer h-[48px] border-[1px] border-[#B30000] px-[48px] bg-white text-[#B30000] justify-center grid' style={{boxShadow:'0.2rem 0.2rem black'}}><p className="my-auto">Enjoy 3-Months free trial</p></button>
            <span data-wow-duration="1s" data-wow-delay=".2s" className='text-[#140505] wow fadeInUp text-center text-[32px] leading-[47.14px] lg:text-[72px] font-[600] lg:leading-[87.14px] px-5 lg:px-20 md:text-[20px] flex flex-col'>
              <span>Charity Gift Aid Management</span>
              <span>System.</span>
            </span>
            <span data-wow-duration="1s" data-wow-delay=".5s" className='text-[#7E7E7E] wow fadeInUp text-center px-5 lg:px-40 text-[24px] tracking-[-1.5%] font-[300]'>Chgams is a stress-free gift-aid management cloud-based software developed to help charity in managing their membership, donations and gift-aid claim process.</span>
            <Link to='/onboard' data-wow-duration="1s" data-wow-delay=".7s" className="wow jello rounded-[8px] flex flex-row bg-[#B30000] text-white items-center py-3 h-[48px] px-8 space-x-3">
              <span>Create an account</span>
              <span><img src={arrow} /></span>
            </Link>
            <div data-wow-duration="1s" data-wow-delay=".8s" className='px-5 lg:px-20 wow fadeIn'><img className='w-[93%] mx-auto' src={hero} /></div>
        </div>
    </>
  )
}

export default Hero