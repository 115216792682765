import React, {useState,useEffect,useRef} from 'react'
import give from "../../../images/icons/give.svg"
import download from "../../../images/icons/download.svg"
import filter from "../../../images/icons/filter.svg"
import search from "../../../images/icons/search.svg"
import calender from "../../../images/icons/calendar.svg"
import edit from "../../../images/icons/edit.svg"
import view from "../../../images/icons/view.svg"
import delet from "../../../images/icons/delete.svg"
import deleteRed from "../../../images/icons/deleteRed.svg"
import more from "../../../images/icons/more.svg"
import { ThreeDots } from 'react-loader-spinner'
import { Link } from 'react-router-dom'
import { CSVLink, CSVDownload } from "react-csv";
import axios from 'axios'
import { LineWave } from  'react-loader-spinner'
import settings from '../../../components/getrequest'
import { format } from 'date-fns';

function Giving() {
  const [base, setBase] = useState(sessionStorage.getItem('prefix') ? sessionStorage.getItem('prefix'): 'user')
  let urlbase = process.env.REACT_APP_BASE;
  const [error, setError] = useState(0)
  const [processing, setProcessing] = useState(0)
  const[fetching, setFetching] = useState(0)

  const [givingId, setGivingId] = useState()
const sliceValue = 8

  const donationsheaders = [
    { label: "id", key: "id" },
    { label: "member id", key: "member_id" },
    { label: "amount", key: "amount" },
    { label: "type", key: "type" },
    { label: "payment date", key: "payment_date" },
    { label: "payment method", key: "payment_method" },
    { label: "member name", key: "name" },
  ]
  const [donationsData, setDonationsData] = useState([])
const [givingArray, setgivingArray] = useState([])
const [currentPage,setCurrentPage] = useState(0)
const[givingCurrent, setgivingCurrent] = useState()

const getGivings = async (url) => {
  axios.get(urlbase+url, settings())
    .then((res) => {
      if (res.data.code === 200) {
        setDonationsData(res.data.data)
        const allData = res.data.data;
        const slicedData = [];
        for (let i = 0; i < allData.length; i += sliceValue) {
          slicedData.push(allData.slice(i, i + sliceValue));
        }
        setgivingCurrent(slicedData[0])
        setgivingArray(slicedData);
      } else {
        setError(res.data.message);
        setProcessing(0);
      }
    })
    .catch((e) => {
      setError('There was an internal error, please try again later');
      setProcessing(0);
    });
}
useEffect(() => {
  getGivings('/api/givings');
}, []);

const PreviousPage = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  setCurrentPage(currentPage > 0 ? currentPage-1 : currentPage)
  if(currentPage > 0){
    setgivingCurrent(givingArray[currentPage-1])
  }
}
const NextPage = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  setCurrentPage(currentPage == givingArray.length-1 ? currentPage : currentPage+1)
  if(currentPage == givingArray.length-1){

  }else{
    setgivingCurrent(givingArray[currentPage+1])
  }
}
const numberedPage = (id) => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  setCurrentPage(id)
  setgivingCurrent(givingArray[id])
}
const [searchItem, setSearchItem] = useState("");
//Active donation
const [activeDonation, setActiveDonation] = useState()
const changeActiveDon = (e,id) => {
  e.stopPropagation()
  if(activeDonation == id) setActiveDonation(null)
  else setActiveDonation(id)
}


//filter
const [filterr, setFilterr] = useState()
const filterAction = (e) => {
  e.stopPropagation();
  setFilterr(!filterr)
}
const filterRef = useRef(null);
const filterRef2 = useRef(null);
const handleOutsideClick = (event) => {
  setTimeout(() => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setFilterr(false);
    }
    if (filterRef2.current && !filterRef2.current.contains(event.target)) {
      setActiveDonation(null)
    }
  }, 0);
};

useEffect(() => {
  document.addEventListener('click', handleOutsideClick);
  return () => {
    document.removeEventListener('click', handleOutsideClick);
  };
}, []);

//giving info
const [givingInfo, setGivingInfo] = useState({})
const [pdate,setPdate] = useState()
function getGivingInfo(id){
  axios.get(urlbase+'/api/givings/get?id='+id, settings())
  .then((res) => {
    if (res.data.code === 200) {
      setGivingInfo(res.data.data)
      setPdate(format(new Date(res.data.data.payment_date),'yyyy-mm-dd'))
      console.log(pdate)
      setActiveDonation(null)
      setGivingModal(1)
    } else {
      setError(res.data.message);
      setProcessing(0);
    }
  })
  .catch((e) => {
    setError('There was an internal error, please try again later');
    setProcessing(0);
  });
}

//update giving
const [givingUpdate,setGivingUpdate] = useState({})
const [updateProcessing, setUpdateProcessing] = useState()
function updateGiving(){
  setUpdateProcessing(1)
  axios.post(urlbase+'/api/givings/update?id='+sessionStorage.getItem('giving_id'),givingUpdate,settings())
  .then((res) => {
    if (res.data.code === 200) {
      setGivingModal(0)
      alert('Giving updated successfully')
      window.location.reload()
    } else {
      setError(res.data.message);
      setProcessing(0);
    }
    setUpdateProcessing(0)
  })
  .catch((e) => {
    setError('There was an internal error, please try again later');
    setProcessing(0);
  });
}

function addUpdateField(field,value){
  setGivingUpdate({...givingUpdate,[field]:value})
}

//edit giving
const [givingModal, setGivingModal] = useState()
const setTheModal = (id) => {
  // setGivingModal(1)
  sessionStorage.setItem('giving_id',id)
  getGivingInfo(id)
}

//delete donation
const [deleteModal, setDeleteModal] = useState()
const [deleteProcessing, setDeleteProcessing] = useState()
const setTheDelete = (id) => {
  sessionStorage.setItem('giving_id',id)
  setActiveDonation(null)
  setDeleteModal(id)
}

const performDelete = () => {
  setDeleteProcessing(1)
  axios.delete(urlbase+'/api/givings?id='+deleteModal,settings())
  .then((res) => {
    if(res.data.code == 200){
      setDeleteModal(null)
      alert('Giving deleted successfully');
      window.location.reload()
    }else{
      alert(res.data.message);
    }
    setDeleteProcessing(0)
  }).catch(() => {
    alert('There was an error, please try again');
    setDeleteProcessing(0)
  })
}

//view details
const viewDetails = (id) => {
  sessionStorage.setItem('giving_id',id)
  window.location.href="/"+base+"/giving-details"
}

//giving types
let [Cash, setCash] = useState(false)
let [Transfer, setTransfer] = useState(false)
let [POS, setPOS] = useState(false)
let [Tithe, setTithe] = useState(false)
let [Offering, setOffering] = useState(false)
let [Others, setOthers] = useState(false)
let [dateF, setDateF] = useState(null)
const givingTypes = [{title:'Cash',set:setCash},{title:'Transfer',set:setTransfer},{title:'POS',set:setPOS},{title:'Tithe',set:setTithe},{title:'Offering',set:setOffering},{title:'Others',set:setOthers}]

  //Applying filter

  useEffect(() => {
    applyFilters();
  }, [dateF,Cash,POS,Tithe,Offering,Others,Transfer]);
  const handleCheckboxChange = (e,name) => {
    // alert(e.target.checked)
    name(e.target.checked)
  };
  const dateFilter = (value) => {
      if(value) setDateF(value)
      else setDateF(null)
  };
    const applyFilters = () => {
      let url = '/api/givings?date='+(dateF?dateF:0)+'&cash='+(Cash ? 1:0)+'&transfer='+(Transfer ? 1:0)+'&POS='+(POS ? 1:0)+'&tithe='+(Tithe ? 1:0)+'&offering='+(Offering ? 1:0)+'&others='+(Others ? 1:0)
        getGivings(url)
    };
let i = 0
const today = new Date().toISOString().split('T')[0];
  return (
    <>
    {deleteModal ?
      <div id="defaultModal" tabIndex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
          <div className="relative w-[50%] lg:w-[35%] mt-[10%] max-h-full mx-auto text-[#333]">
              <div className="relative bg-white shadow pt-4 rounded-[8px]">
                  <div className="flex items-start justify-between px-8 pb-4">
                      <h3 className="text-[#121927] text-[20px] font-[600]">
                      </h3>
                      <button onClick={() => setDeleteModal(0)} type="button" className="text-gray-300 font-[200] bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center" data-modal-hide="defaultModal">
                          <svg aria-hidden="true" className="w-5 h-5 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                          <span className="sr-only">Close modal</span>
                      </button>
                  </div>
                  <div className='flex flex-col space-y-4 mt-3 px-5 text-center'>
                    <div className='grid mb-2'><img className='mx-auto' src={deleteRed} /></div>
                    <span className='text-[#121927] font-[600] text-[24px]'>Delete Giving Details</span>
                    <span className='text-[#4F4F4F] text-[16px]'>Deleted donation can not be retrieved. Are you sure you want to delete?</span>
                  </div>
                  <div className="flex flex-col mt-5 space-y-3 px-8 py-4">
                  { deleteProcessing ? <LineWave height="100" width="100" color="#B30000" ariaLabel="line-wave" wrapperStyle={{marginTop:'-40px'}}
                      wrapperClass="" visible={true} firstLineColor="" middleLineColor="" lastLineColor=""/>
                       :
                      <>
                      <button onClick={performDelete} data-modal-hide="defaultModal" type="button" className="border-[1px] w-full h-[50px] bg-[#EB5757] rounded-[8px] border-none text-white">Yes, delete donation</button>
                      <button onClick={() => setDeleteModal(0)} data-modal-hide="defaultModal" type="button" className="border-[#828282] w-full h-[50px] rounded-[8px] bg-[#F7F7F7] text-[#333333]">No, Cancel</button>
                      </> }
                  </div>
              </div>
          </div>
      </div>
      :null}

    {givingModal ?
      <div id="defaultModal" tabIndex="-1" aria-hidden="true" className="fixed top-0 h-screen bg-black bg-opacity-50 inset-0 items-center justify-center z-50 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
          <div className="relative w-[50%] lg:w-[40%] mt-[1%] max-h-full mx-auto text-[#333]">
              <div className="relative bg-white shadow pt-3">
                  <div className="flex items-start justify-between border-b-[1px] px-8 pb-3">
                      <h3 className="text-[#121927] text-[20px] font-[600]">
                          Edit Giving Details
                      </h3>
                      <button onClick={() => setGivingModal(0)} type="button" className="text-gray-300 font-[200] bg-transparent hover:bg-[#F2F2F2] hover:text-black rounded-full p-1.5 ml-auto inline-flex items-center" data-modal-hide="defaultModal">
                          <svg aria-hidden="true" className="w-5 h-5 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                          <span className="sr-only">Close modal</span>
                      </button>
                  </div>
                  <div className='flex flex-col space-y-7 mt-3'>
                    <div className="mt-5 grid flex flex-col text-start space-y-2 px-8">
                        <div className='text-[#505050]'>Member ID</div>
                        <input className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F]' readOnly defaultValue={givingInfo.member_id ? givingInfo.member_id : ''} placeholder='member Id'/>
                    </div>
                    <div className="mt-5 grid flex flex-col text-start space-y-2 px-8">
                        <div className='text-[#505050]'>Member Name</div>
                        <input className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F]' readOnly defaultValue={givingInfo.name ? givingInfo.name : ''} placeholder='member name'/>
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-x-5 gap-y-2 mt-3 px-8'>
                    <div className="col-span-1 mt-5 grid flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Giving Type</div>
                        <select onChange={(e) => addUpdateField('type',e.target.value)}  className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] w-[100%]'>
                          <option selected={givingInfo.type == 1 ? true : false} value={1}>Individual(Offering)</option>
                          <option selected={givingInfo.type == 2 ? true : false} value={2}>Individual(Tithe)</option>
                          {/* <option selected={givingInfo.type == 3 ? true : false} value={3}>GASD</option>
                          <option selected={givingInfo.type == 4 ? true : false} value={4}>Aggregated</option> */}
                        </select>
                    </div>
                    <div className="col-span-1 mt-5 grid flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Payment method</div>
                        <select onChange={(e) => addUpdateField('payment_method',e.target.value)} className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] w-[100%]'>
                          <option selected={givingInfo.payment_method == 'Bank Transfer' ? true : false} value='Bank Transfer'>Bank Transfer</option>
                          <option selected={givingInfo.payment_method == 'Cash' ? true : false} value='Cash'>Cash</option>
                          <option selected={givingInfo.payment_method == 'POS'? true : false} value={3}>POS</option>
                        </select>
                    </div>
                    <div className="col-span-1 mt-5 grid flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Amount (£)</div>
                        <input onChange={(e) => addUpdateField('amount',e.target.value)} className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F]' defaultValue={givingInfo.amount} placeholder='amount'/>
                    </div>
                    <div className="col-span-1 mt-5 grid flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Payment date</div>
                        <input max={today} onChange={(e) => addUpdateField('payment_date',e.target.value)} type='date' className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F]' defaultValue={pdate ? new Date(pdate) : ''}/>
                    </div>
                    <div className="col-span-2 mt-5 grid flex flex-col text-start space-y-2">
                        <div className='text-[#505050]'>Comment</div>
                        <textarea onChange={(e) => addUpdateField('comment',e.target.value)} className='border-[1px] rounded-[8px] p-3 outline-none text-[#4F4F4F] h-[118px]' placeholder='Type comment here' defaultValue={givingInfo.comment}></textarea>
                    </div>
                  </div>
                  <div className="flex flex-row justify-end mt-5 space-x-2 px-8 bg-[#F7F7F7] py-4">
                    { updateProcessing ? <LineWave height="100" width="100" color="#B30000" ariaLabel="line-wave" wrapperStyle={{marginTop:'-40px'}}
                      wrapperClass="" visible={true} firstLineColor="" middleLineColor="" lastLineColor=""/>
                       :
                      <>
                      <button onClick={() => setGivingModal(0)} data-modal-hide="defaultModal" type="button" className="border-[1px] border-[#828282] w-[102px] h-[50px] rounded-[8px]">Cancel</button>
                      <button onClick={updateGiving} data-modal-hide="defaultModal" type="button" className="border-[1px] w-[178px] h-[50px] bg-[#B30000] rounded-[8px] border-none text-white">Update Changes</button>
                    </>}

                  </div>
              </div>
          </div>
      </div>
      :null}

      <div className={`grid grid-cols-1 mt-5 mb-5 px-8 ${givingModal ? 'opacity-25' : ''}`}>
        <div className='col-span-1 flex lg:flex-row flex-col justify-between space-y-4 lg:space-y-0'>
          <p className='text-[#292D32] text-[24px] font-[600] text-start'>All Giving</p>
          
          <div className='flex flex-row space-x-4'>
          {sessionStorage.getItem('role') != 1 ? null :
          donationsData.length > 1 ?
          <CSVLink target='_blank' filename={"donations.csv"} data={donationsData} headers={donationsheaders}>
            <div className='border-[1px] h-[40px] px-3 cursor-pointer rounded-[4px] flex flex-row p-2 items-center space-x-3'>
              <img src={download} />
              <p className='text-[#292D32] text-[15px] font-[600]'>Download CSV</p>
            </div> 
            </CSVLink> :null } 
            <Link to={'/'+base+'/add-giving'} className='border-[1px] bg-[#B30000] px-2 h-[40px] cursor-pointer rounded-[4px] flex flex-row p-2 items-center space-x-3'>
              <img src={give} />
              <p className='text-white text-[15px] font-[600]'>Add giving</p>
            </Link>
          </div>
        </div>
        <div className='col-span-1 flex lg:flex-row flex-col space-y-4 lg:space-y-0 justify-between mt-8'>
          <div className='relative'>
            <div className='absolute top-3 z-50 left-4'><img src={search} /></div>
            <input onChange={(e) => setSearchItem(e.target.value)} className='relative w-[321px] bg-[#F7F7F7] h-[40px] pl-10 rounded-[8px] border-[1px] text-[#8A8A8A] outline-none' placeholder='Search' />
          </div>
          <div className='flex flex-row space-x-4 items-center'>
            <div className=''>
              {/* <div className='absolute '></div> */}
              <input onChange={(e) => dateFilter(e.target.value)} className='relative border-[1px] h-[40px] rounded-[4px] outline-none border-[#D9D9D9] p-1' type='date' />
            </div>
            <div>
              <div onClick={(e) => filterAction(e)} className='flex flex-row space-x-3 border-[1px] rounded-[4px] p-1 px-3 h-[40px] cursor-pointer'>
                <img src={filter} />  
                <span>Filter</span>
              </div>
              {filterr ?
              <div ref={filterRef} className='absolute rounded-[4px] flex flex-col z-50 bg-white space-y-3 shadow-md right-10 py-2'>
                {givingTypes.map((item,id) => {
                  return (
                    <div key={id} className='flex flex-row space-x-2 px-4 cursor-pointer hover:bg-[#F7F7F7] py-2'>
                      <input type='checkbox' checked={item.title == 'Cash' ? Cash : item.title == 'Transfer' ? Transfer : item.title == 'POS' ? POS : item.title == 'Tithe' ? Tithe : item.title == 'Offering' ? Offering : Others} className='accent-[#B30000]' onChange={(e) => handleCheckboxChange(e,item.set)}/>
                      <p>{item.title}</p>
                    </div>
                  )
                })}
              </div>
              : null}
            </div>
          </div>
        </div>

        {donationsData.length > 0 ? <>
        <div className='col-span-1 mt-5 border-[1px] rounded-[8px]'>
          <table className="table-auto overflow-x-scroll w-full block divide-y divide-gray-200 ">
            <thead className='bg-[#F7F7F7]'>
                <tr className='px-6 py-1 text-[#292D32] font-[400]'>
                {/* <th className='px-6 py-3 text-star'><input type='checkbox' className='accent-[#B30000]' /></th> */}
                <th className='px-5 py-1 text-start text-[#292D32] font-[400]'>S/N</th>
                <th className='px-5 py-1 text-start text-[#292D32] font-[400]'>Donor Name</th>
                <th className='px-5 py-1 text-start text-[#292D32] font-[400]'>Date & Time</th>
                <th className='px-5 py-1 text-start text-[#292D32] font-[400]'>Member ID</th>
                <th className='px-5 py-1 text-start text-[#292D32] font-[400]'>Amount</th>
                <th className='px-5 py-1 text-start text-[#292D32] font-[400]'>Giving Type</th>
                <th className='px-5 py-1 py-1 text-start text-[#292D32] font-[400]'>Payment method</th>
                {sessionStorage.getItem('role') != 1 && sessionStorage.getItem('role') != 2 ? null :
                <>
                <th className='px-5 py-1 text-start text-[#292D32] font-[400] text-[#F7F7F7]'>Edit</th>
                <th className='px-5 py-1 text-start text-[#292D32] font-[400] text-[#F7F7F7] w-full'>Delete</th>
                </> }
                </tr>
            </thead>
            <tbody className='divide-y divide-gray-200'>
                {givingCurrent.filter((row) =>
                !searchItem.length || (row.name?row.name:'')
                .toString()
                .toLowerCase()
                .includes((searchItem ? searchItem : '').toString().toLowerCase()) 
                ).map(({id,name,member_id,payment_date,amount,type,payment_method},index) => {
                    return (
                        <tr key={id}>
                            {/* <td className='px-6 py-3 text-star'><input type='checkbox' className='accent-[#B30000]' /></td> */}
                            <td className='px-5 py-3 text-start text-[#4F4F4F]'>{++i}</td>
                            <td className='px-5 py-3 text-start text-[#4F4F4F]'>{name ? name : '-------'}</td>
                            <td className='px-5 py-3 text-start text-[#4F4F4F]'>{format(new Date(payment_date),'yyyy-MM-dd')}</td>
                            <td className='px-5 py-3 text-start text-[#4F4F4F]'>{member_id ? member_id : '----------'}</td>
                            <td className='px-5 py-3 text-start text-[#4F4F4F]'>£{amount.toLocaleString()}</td>
                            <td className='px-5 py-3 text-start text-[#4F4F4F]'>{(type == 1 ? 'Individual(Offering)' : type == 2 ? 'Individual(Tithe)' : 'Others')}</td>
                            <td className='px-5 py-3 text-start text-[#4F4F4F]'>{payment_method}</td>
                            {sessionStorage.getItem('role') != 1 && sessionStorage.getItem('role') != 2 ? null :
                            <>
                            <td className='px-5 py-3 text-start text-[#4F4F4F]'><img className='w-[20px] h-[20px] cursor-pointer' onClick={() => setTheModal(id)} src={edit} /></td>
                            
                            <td className='px-5 py-3 text-start text-[#4F4F4F]'>
                              <span onClick={(e) => changeActiveDon(e,index)} className='relative cursor-pointer'><img className='' src={more} /></span>
                              {activeDonation == index ? <span ref={filterRef2} className='absolute right-10 z-50 py-2 shadow-md rounded-[8px] bg-white flex flex-col'>
                                <span onClick={() => setTheDelete(id)} className='px-4 py-2 cursor-pointer hover:bg-[#F7F7F7] flex flex-row items-center space-x-3 text-[12px]'>
                                  <span><img src={delet} /></span>
                                  <span>Delete</span>
                                </span>
                                <span onClick={() => viewDetails(id)} className='px-4 py-2 cursor-pointer hover:bg-[#F7F7F7] flex flex-row items-center space-x-3 text-[12px]'>
                                  <span><img src={view} /></span>
                                  <span>View Details</span>
                                </span>
                              </span> : null
                              }
                            </td></>}
                        </tr>
                        )
                    }) 
                }
            </tbody>
          </table>
        </div>
        <div className='col-span-1 flex flex-row justify-between items-center'>
          {searchItem? '' : <span className='text-[#333333] text-[16px]'>Showing <span>{givingCurrent.length}</span>/<span>{donationsData.length}</span> results</span>}
          {fetching ? 
            <div className='col-span-8 lg:col-span-6 flex flex-col justify-center items-center '>
                <ThreeDots height="80" width="80" radius="9" color="#333333" ariaLabel="three-dots-loading"
                wrapperStyle={{}} wrapperClassName="" visible={true}
                />
            </div> 
            :
            
          <div className={`ml-auto grid grid-cols-6 gap-x-4`}>
            {givingArray.length > 0 ? 
                <div className={`mt-5 col-span-6 flex flex-row gap-x-3 mb-5 lg:ml-auto`}>
                    <div onClick={PreviousPage} className={`border-[#BCBCBC] h-[40px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto border-[1px] px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center text-[#BCBCBC] hover:text-[#1D242D] cursor-pointer`}>{`<`}</div>
                    {givingArray.slice(0,5).map((givingArray,index)=> {
                        return (
                            <div key={index} onClick={()=>numberedPage(index)} className={`transitiion-all h-[40px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto text-start duration-500 border-[#BCBCBC] font-[600] text-[18px] border-[1px] px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center ${currentPage == index ? 'text-white bg-[#B30000] border-[#B30000] hover:bg-[#B30000] hover:text-white' :'text-[#292D32] bg-white hover:text-white hover:bg-[#B30000]'} hover:text-[#1D242D] cursor-pointer`}>{index+1}</div>
                        )
                    })}
                    <div onClick={NextPage} className={`border-[#BCBCBC] border-[1px] lg:h-auto max-h-[46px] w-[35px] lg:w-auto px-2 lg:px-4 py-1 lg:py-2 rounded-[6px] items-center text-[#BCBCBC] hover:text-[#1D242D] cursor-pointer`}>{`>`}</div>
                </div>
                : '' }
            </div> 
          }
        </div>
        </> : <div>No donations retrieved</div>}

      </div>
    </>
  )
}

export default Giving